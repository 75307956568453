import * as ActionTypes from '../ActionTypes';

/**
 * 报告状态管理器
 * @param state 
 * @param action 
 * @returns 
 */
export default function report(
    state = {
        reportInfo: {
            evaluationNormal: '',
            evaluationBadPosture: '',
            evaluationLateralBendingOne: '',
            evaluationLateralBendingTwo: '',
            evaluationLateralBendingThree: '',
            fileName: '',
            filePath: '',
            logoData: '',
            id: 0,
            logoType: '',
            examineOrgInfo: '',
            orgId: 0,
        },
    },
    action: { type: string, data: any }
) {
    switch (action.type) {
        case ActionTypes.SET_REPORT_INFO:
            return {
                ...state,
                reportInfo: { ...action.data }
            };
        default:
            return state;
    }
}

export type TReoprtState = Parameters<typeof report>[0];
export type TReoprtAction = Parameters<typeof report>[1];