
import VideoPart from "@/components/videoPart";
import { Tooltip, Modal } from 'antd';

import { PlayCircleOutlined } from '@ant-design/icons';
import Style from "../index.module.less"
import { useState } from "react"
/**
 * 弹窗播放运动库视频
 * @param props 
 * @returns 
 */
function PlayActionVideo(props) {
    const { record } = props

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className={Style['video_mini']}>
            <Tooltip title="点击播放视频" className={Style['videoPic']}>
                <img style={{ width: "60px" }} src={record.postUrl} alt="" />
                {record.postUrl &&
                    <PlayCircleOutlined
                        className={Style['playBtn']}
                        style={{ color: '#ffffff' }}
                        onClick={showModal} />}
            </Tooltip>

            <Modal open={isModalOpen} onCancel={handleCancel} footer={null}
                className={'video_modal'}
                width={'fit-content'}
            >
                <VideoPart videoUrl={record.videoUrl}  width="100%" height="300" autoPlay={true}/>
            </Modal>
        </div>
    )
}
export default PlayActionVideo