

import { SET_USERS_SEARCH, SET_USERS_LIST, SET_CURRENT_USER } from '../ActionTypes'
import { apiGetUsersList, apiAddUser, apiEditUser, apiDelUser, apiResetPwd } from "../../actions/api"
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';
import { delSearchNull } from '../../utils/dataUtils'
import { cloneDeep } from "lodash"
import { message } from 'antd'
import intl from 'react-intl-universal';
import { getUserRoleTypeStr, getCurrentUser } from "../selecters/platform.js"
import { isHadLogin, initUser } from "./platform.js"
export const setUsersSearch = (data) => {
    return {
        type: SET_USERS_SEARCH,
        data
    }
}
export const setUsersList = (data) => {
    return {
        type: SET_USERS_LIST,
        data
    }
}
export const setCurrentUser = (data) => {
    return {
        type: SET_CURRENT_USER,
        data
    }
}


/*************************************************************************************** */
export function* watchSwitchUserSearch() {
    yield takeLatest('SET_USERS_SEARCH', getUsersList);
}


export function* getUsersList() {
    let search = delSearchNull(store.getState().users.usersSearch)
    if (isHadLogin()) {
        try {
            const data = yield call(apiGetUsersList, search);
            const Elist = {
                list: formdataUserlist(data.rows),
                total: data.total
            };
            yield put(setUsersList(Elist))
        } catch {
            cancelled()
        } finally {

        }
    }
}

export function getUsersListBySearch() {
    let search = delSearchNull(store.getState().users.usersSearch)
    return function (dispatch) {
        apiGetUsersList(search)
            .then(data => {
                if (data.rows.length === 0 && search.page > 1) {
                    dispatch(setUsersSearch({ page: search.page - 1 }))
                } else {
                    const Elist = {
                        list: formdataUserlist(data.rows),
                        total: data.total
                    };
                    dispatch(setUsersList(Elist))
                }
            }).catch(() => { })
    }
}



export function addUser(data, updateModal) {
    let currentUser = getCurrentUser(store.getState())
    data.roleType = data.role
    if (currentUser.role === 1) delete data.orgId
    if (data.tel === "") delete data.tel
    return function (dispatch) {
        apiAddUser(data)
            .then(() => {
                message.success(intl.get("ACTION.ADD_SUCCESS"))
                dispatch(getUsersListBySearch())
                updateModal.current.handleCancel();
            }).catch(() => { })
    }
}


export function editUser(data, updateModal) {
    let currentUser = getCurrentUser(store.getState())
    // if (currentUser.role === 1) delete data.orgId
    let res = {
        id: data.id,
        username: data.username,
        tel: data.tel,
        role: data.role,
        roleType: data.role,
        orgId: data.orgId,
        errorCounter: data.errorCounter,
    }
    return function (dispatch) {
        apiEditUser(res)
            .then(() => {
                if (updateModal === 'currentUser') {
                    message.success(intl.get("ACTION.EDIT_SUCCESS"))
                    dispatch(initUser({ ...currentUser, tel: data.tel }))
                } else if (updateModal === 'unlockUser') {
                    message.success(intl.get("ACTION.UNLOCK_SUCCESS"))
                    dispatch(getUsersListBySearch())
                } else {
                    message.success(intl.get("ACTION.EDIT_SUCCESS"))
                    dispatch(getUsersListBySearch())
                    updateModal.current.handleCancel();
                }
            }).catch(() => { })
    }
}

export function delUserById(ids) {
    return function (dispatch) {
        apiDelUser({ ids })
            .then(() => {
                message.success(intl.get("ACTION.DEL_SUCCESS"))
                dispatch(getUsersListBySearch())
            }).catch(() => { })
    }
}

export function resetUserPwd(data) {
    return function (dispatch) {
        apiResetPwd(data)
            .then(() => {
                message.success(intl.get("ACTION.RESET_PWD_SUCCESS"))
                dispatch(getUsersListBySearch())
            }).catch(() => { })
    }
}



function formdataUserlist(list) {
    let l = cloneDeep(list)
    l.map(item => {
        item.roleName = getUserRoleTypeStr(store.getState())[item.roleType]
        return item
    })
    return l
}
