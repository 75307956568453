/**
 * 记录所有action的类型
 */

/**platform.js*********************************************************/
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_COMMON_INFO = 'SET_COMMON_INFO'
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS'
/**patients.js*********************************************************/
export const SET_PATIENT_LIST = 'SET_PATIENT_LIST'
export const SET_PATIENT_SEARCH = 'SET_PATIENT_SEARCH'
export const SET_PATIENT_ORG = 'SET_PATIENT_ORG'
export const SET_QR_CANVAS = 'SET_QR_CANVAS'

/**examine.js*********************************************************/
export const SET_EXAMS_LIST = 'SET_EXAMS_LIST'
export const SET_EXAMS_SEARCH = 'SET_EXAMS_SEARCH'
/**orgs.js*********************************************************/
export const SET_ORGS_SEARCH = 'SET_ORGS_SEARCH'
export const SET_ORGS_LIST = 'SET_ORGS_LIST'
export const SET_ORGS_LIST_DV = 'SET_ORGS_LIST_DV'
export const SET_SCHOOL_LIST = 'SET_SCHOOL_LIST'
/**users*************************************************************** */
export const SET_USERS_SEARCH = 'SET_USERS_SEARCH'
export const SET_USERS_LIST = 'SET_USERS_LIST'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
/**projects*************************************************************** */
export const SET_PROJECT_SEARCH = 'SET_PROJECT_SEARCH'
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST'
export const SET_PROJECTS_LIST_DV = 'SET_PROJECTS_LIST_DV'
/***btcode************************************************************ */
export const SET_BTCODE_SEARCH = 'SET_BTCODE_SEARCH'
export const SET_BTCODE_LIST = 'SET_BTCODE_LIST'
/***logs***************************************************************** */
export const SET_LOGS_SEARCH = 'SET_LOGS_SEARCH'
export const SET_LOGS_LIST = 'SET_LOGS_LIST'
/***report***************************************************************** */
export const SET_REPORT_INFO = 'SET_REPORT_INFO';
/**actionWarehouse.js*********************************************************/
export const SET_ACTION_WAREHOUSES_LIST = 'SET_ACTION_WAREHOUSES_LIST'
export const SET_ACTION_WAREHOUSES_SEARCH = 'SET_ACTION_WAREHOUSES_SEARCH'
/**exePrescription.js*********************************************************/
export const SET_CHECKED_EXE_LIST = 'SET_CHECKED_EXE_LIST'//选中的运动库视频信息列表
export const SET_CASE_INFO = 'SET_CASE_INFO'//处方信息
export const SET_EXE_PRESCRIPTION_LIST = 'SET_EXE_PRESCRIPTION_LIST'//处方列表
export const SET_EXE_PRESCRIPTION_SEARCH = 'SET_EXE_PRESCRIPTION_SEARCH'//处方列表查询条件
export const SET_ESELECTED_PAT_TO_EXE = 'SET_ESELECTED_PAT_TO_EXE'//选中的受检人（向其开具处方）
export const SET_ESELECTED_UPDATE_EXE_ID = 'SET_ESELECTED_UPDATE_EXE_ID'//选中的处方信息
export const SET_SYSTEM_EXE_PRESCRIPTION_LIST = 'SET_SYSTEM_EXE_PRESCRIPTION_LIST'//系统处方列表
export const SET_SYSTEM_EXE_PRESCRIPTION_SEARCH = 'SET_SYSTEM_EXE_PRESCRIPTION_SEARCH'//系统处方列表查询条件

