// @ts-ignore
import throttle from 'lodash/throttle';
import { createStore, applyMiddleware } from "redux";
import reducer from "../redux/reducer/index.js"; // 引入组合后的reducer
import thunk from "redux-thunk"; // 异步中间件
import createSagaMiddleware from 'redux-saga'

import { composeWithDevTools } from 'redux-devtools-extension';
import { watchSwitchPatientSearch } from "../redux/actions/patients.js"
import { watchSwitchExamsSearch } from "../redux/actions/exams.js"
import { watchSwitchUserSearch } from "../redux/actions/users.js"
import { watchSwitchProjectsSearch } from "../redux/actions/projects.js"
import { watchSwitchOrgsSearch } from "../redux/actions/orgs.js"
import { watchSwitchBtCodeSearch } from "../redux/actions/btCode.js"
import { watchSwitchLogsSearch } from "../redux/actions/logs.js"
import { watchSwitchactionWarehousesSearch } from "../redux/actions/actionWarehouse.js"
import { watchSwitchExePrescriptionSearch,watchSwitchSystemExePrescriptionSearch } from "../redux/actions/exePrescription.js"

import { all } from 'redux-saga/effects';
let middleware = [thunk];

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        } else {
            let state = JSON.parse(serializedState);
            return state;
        }
    } catch (e) {
        return undefined;
    }
};

const peristedState = loadState();

// 本地存储state
const saveState = (state: any) => {
    try {
        const copyState = { ...state };

        // report 状态不需要持久化
        delete copyState.report;

        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors;
    }
};
const saga = createSagaMiddleware();
const store = createStore(reducer, peristedState, composeWithDevTools(applyMiddleware(...middleware, saga)));
store.subscribe(
    // 节流
    throttle(() => {
        saveState(store.getState());
    }, 1000)
);

function* rootSaga() {
    yield all([
        watchSwitchPatientSearch(),
        watchSwitchExamsSearch(),
        watchSwitchUserSearch(),
        watchSwitchProjectsSearch(),
        watchSwitchOrgsSearch(),
        watchSwitchBtCodeSearch(),
        watchSwitchLogsSearch(),
        watchSwitchactionWarehousesSearch(),
        watchSwitchExePrescriptionSearch(),
        watchSwitchSystemExePrescriptionSearch(),
    ]);
}

saga.run(rootSaga);

export default store;