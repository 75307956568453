import {
  getCurrentUser,
  getUserRoleTypeStr,
} from "../redux/selecters/platform";
import store from "../store";
/**功能定义*************************************************** */
/**医疗机构*************** */
//orgList:menu
//orgList:edit
//orgList:del
//orgList:add
//orgList:onlyView
/**受检单位*************** */
//schoolList:menu
//schoolList:edit
//schoolList:del
//schoolList:add
//schoolList:onlyView
/**用户管理*************** */
//userList:menu
//userList:edit
//userList:add
//userList:del
//userList:reSetPwd
//userList:onlyView
//userList:unlock
/**受检人管理*************** */
//patientList:menu
//patientList:edit
//patientList:add
//patientList:del
//patientList:upload
//patientList:onlyView
/**项目管理*************** */
//project:menu
//project:add
//project:edit
//project:onlyView
/**检查记录*************** */
//exam:menu
/**数据统计*************** */
//statistic:menu
/**个人信息模块************** */
//personal:menu
/**蓝牙码管理*************** */
//btCode:menu
/**蓝牙码下载模块*************** */
//btCodeDownLoad:menu
/**APP下载模块*************** */
//APPDownLoad:menu
export function getRoleLimits(role) {
  //{"0": "系统管理员","1": "检测中心管理员","2": "检测中心医生"}
  if (role === 0) {
    return [
      "orgList:menu",
      "orgList:add",
      "orgList:edit",
      "userList:menu",
      "userList:add",
      "userList:edit",
      "userList:del",
      "userList:reSetPwd",
      "userList:unlock",
      "btCode:menu",
      "personal:menu",
      "APPDownLoad:menu",
      "actionWarehouseList:edit",
      "actionWarehouseList:del",
    ];
  } else if (role === 1) {
    return [
      "schoolList:menu",
      "schoolList:edit",
      "schoolList:add",
      "patientList:menu",
      "patientList:edit",
      "patientList:add",
      "patientList:del",
      "patientList:upload",
      "userList:menu",
      "userList:add",
      "userList:edit",
      "userList:del",
      "userList:reSetPwd",
      "userList:unlock",
      "project:menu",
      "project:add",
      "project:edit",
      "exam:menu",
      "statistic:menu",
      "personal:menu",
      "APPDownLoad:menu",
    ];
  } else if (role === 2) {
    return [
      "schoolList:menu",
      "schoolList:onlyView",
      "patientList:menu",
      "project:menu",
      "project:onlyView",
      "exam:menu",
      "statistic:menu",
      "personal:menu",
      "APPDownLoad:menu",
    ];
  } else if (role === 3) {
    return ["btCodeDownLoad:menu"];
  }
}

export function isHasPermissions(actionName) {
  const permissions = getCurrentUser(store.getState()).permissions;
  if (permissions && permissions.includes(actionName)) {
    return true;
  } else {
    return false;
  }
}

export function getRoleNameByRoleId(roleId) {
  const roles = getUserRoleTypeStr(store.getState());
  return roles[roleId];
}
