import request from '@/utils/request';

export * from './examine-history';
export * from './statistic-analysis';

type TAxiosResponseData<T> = {
    total: number;
    rows: T;
    code: string;
    msg?: any;
    errorCode?: any;
    errorMsg?: any;
}

/**
 * 获取报告信息接口
 * @param params 
 */
export const getReportConfigRequest = () => {
    return request.get<any, TAxiosResponseData<API.TGetReportTemplateInfoResponseData[]>>('/api/v1/template/info');
}

/**
 * 更新报告配置接口
 * @param params 
 */
export const updateReportConfigRequest = (data: FormData) => {
    return request.post('/api/v1/template/config', data, { headers: { 'Content-Type': 'multipart/form-data' } });
}

/**
 * 获取scolionet报告接口
 * @param params 
 */
export const getScolionetReportRequest = (params?: any) => {
    return request.get<any, TAxiosResponseData<{ fileUrl: string; }[]>>('/api/v1/201/exams/info', { params });
}

/**
 * 下载excel
 * @param params 
 * @description 接收blob文件流, 所以需要设置响应类型为blob
 */
export const downloadExcelRequest = (params?: any) => {
    return request.get('/api/v1/exams/exportExcel', { params, responseType: 'blob' });
}

/**
 * 获取微信小程序的jssdk鉴权票据
 * @description H5作为微信小程序的webview并使用小程序的api时, 需要获取微信小程序的js-sdk鉴权票据
 * @param params 
 */
export const getWxMpJSSDKTicket = (data: { url: string }) => {
    return request.post<any, TAxiosResponseData<API.TGetWxMpJSSDKTicket[]>>('/api/media/platform/getWXSign', data);
}

/**
 * * 小程序端web-view页面获取快筛报告数据接口
 * @field childId 绑定孩子id
 */
export const getQuickReportInWxMp = (params: { childId: string }) => {
    return request.get<any, TAxiosResponseData<{
        quickData: API.TExamineHistoryRespData; templateData: API.TGetReportTemplateInfoResponseData;
    }[]>>('/api/dtx/report/wecat/quick', { params });
}

/**
 * * 通过扫码进入表单页面添加受检人接口
 * @field childId 绑定孩子id
 */
export const addPatientByQrcode = (data: any) => {
    return request.post('/api/v1/patient/qr/add', data);
}