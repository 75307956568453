import intl from 'react-intl-universal';
import { validatePhone, validatePwd } from "../../utils/regUtils"
export const preUserInfo = { username: '', roleType: '', orgId: "", pw: "", tel: "" }

export const userSearch = [
    {
        name: 'username',
        label: "USERS.USERNAME",
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 5,
        offset: 0,
        maxLength: 15
    },
    {
        name: 'role',
        label: "USERS.ACTOR",
        value: "",
        type: "select",
        options: [],
        colSpan: 3,
        offset: 1,
    }
]

export function usersColumns() {
    return [
        {
            title: intl.get('USERS.USERNAME'),
            dataIndex: 'username',
            // sorter: true,
            width: 120,
            align: 'center'
        },
        {
            title: intl.get('USERS.ROLENAME'),
            dataIndex: 'roleName',
            //sorter: true,
            width: 120,
            align: 'center'
        },
        {
            title: intl.get('USERS.ORGNAME'),
            dataIndex: 'orgName',
            //sorter: true,
            width: 120,
            align: 'center'
        },
        {
            title: intl.get('USERS.TEL'),
            dataIndex: 'tel',
            //sorter: true,
            width: 120,
            align: 'center'
        },
    ];
}

// 弹窗表单数据
export function userForm() {
    return [
        {
            name: 'username',
            label: "USERS.USERNAME",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 15,
            message: "USERS.USERNAME_MSG",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add'],
            role: [0, 1]
        },
        {
            name: 'username',
            key: "username",
            label: "USERS.USERNAME",
            value: "",
            type: "text",
            options: [],
            message: "USERS.USERNAME",
            modal: ['edit'],
            role: [0, 1]
        },
        {
            name: 'pw',
            label: "USERS.PWD",
            value: "",
            type: "input",
            defaultValue: "",
            minLength: 8,
            maxLength: 16,
            message: "USERS.PWD_MSG",
            rules: [
                {
                    required: true,
                    message: intl.get('USERS.PASSWORD_FORMAT')
                },
                {
                    ...validatePwd,
                    message: intl.get('USERS.PASSWORD_FORMAT')
                }
            ],
            modal: ['add'],
            role: [0, 1]
        },
        // 手机号表单数据
        {
            name: 'tel',
            label: "USERS.TEL",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 20,
            message: "USERS.TEL_MSG",
            // 校验规则
            rules: [
                {
                    ...validatePhone,
                    message: intl.get('USERS.TEL_MSG_ERROR')
                }
            ],
            modal: ['add', 'edit'],
            role: [0, 1]
        },
        {
            name: 'role',
            key: 'role',
            label: "USERS.ACTOR",
            value: "",
            type: "select",
            options: [],
            message: "USERS.ACTOR_MSG",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
            role: [0, 1]
        },
        {
            name: 'orgId',
            key: "orgId",
            label: "USERS.ORGNAME",
            value: "",
            type: "select",
            options: [],
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            message: "USERS.ORGNAME",
            modal: ['add'],
            role: [0]
        },
        {
            name: 'orgName',
            key: "orgName",
            label: "USERS.ORGNAME",
            value: "",
            type: "text",
            options: [],
            message: "USERS.ORGNAME",
            modal: ['edit'],
            role: [0]
        }
    ]
}