import intl from 'react-intl-universal';
import { cloneDeep } from "lodash";
import { md5 } from './md5.js';


export const handleQuickData = (examineDataList) => {
	if (!examineDataList[0].data.length) {
		return '左0°,右0°';
	}
	const [num0, num1] = examineDataList[0].data.slice(0, 2).map(item => Number(item));
	const absNum0 = Math.abs(num0);
	const absNum1 = Math.abs(num1);
	if (num0 === num1) {
		return '左0°,右0°';
	}
	if (num0 > num1) {
		return `左${num0}°,右${absNum1}°`
	}
	return `左${num1}°,右${absNum0}°`
}

export function isNoVal(data) {
	return [undefined, null, ''].includes(data) ? true : false;
}
/**
 * 初始化redux的值
 * @param {Array} searchData 
 * @returns 
 */
export const reduxSearchData = (searchData) => {
	let obj = {}
	searchData.forEach(item => {
		obj[item.name] = obj[item.name] === undefined ? undefined : ''
	})
	// obj.per_page = 20
	obj.size = 20
	obj.page = 1
	obj.asc = ''
	obj.desc = ''
	return obj
}

/**
 * 根据出生日期计算年龄
 * @param {string} val 
 * @returns 
 */
export function getPatientAge(val) {
	if (val) {
		let currentYear = new Date().getFullYear() //当前的年份
		let calculationYear = new Date(val).getFullYear() //计算的年份
		const wholeTime = currentYear + val.substring(4) //周岁时间
		const calculationAge = currentYear - calculationYear //按照年份计算的年龄
		//判断是否过了生日
		if (new Date().getTime() > new Date(wholeTime).getTime()) {
			return calculationAge
		} else {
			return calculationAge - 1
		}
	}
}
/**
 * 获取性别
 * @param {string} val m/f/o
 * @returns 
 */
export function getPatientSex(val) {
	return val === 'm' ? intl.get('PATIENT.MALE') : val === 'f' ? intl.get('PATIENT.FEMALE') : intl.get("PATIENT.OTHER_SEX")
}
/*search***************************************************************************************************************** */
/**
 * 格式化查询条件
 * @param {Array} search 
 * @returns 非空查询条件
 */
export function delSearchNull(search) {
	let s = cloneDeep(search)
	for (var key in s) {
		if (s[key] === '') {
			delete s[key]
		}

	}
	return s
}
/**
 * 格式化select/radio的选项
 * @param {Array} options 
 * @returns 
 */
export function formOptions(options) {
	let ps = cloneDeep(options)
	ps.forEach(item => {
		item.label = intl.get(item.label)
	})
	return ps
}
/**
 * 根据数据量和单页数据量计算总页数
 * @param {number} len 数据量
 * @param {number} size 单页数据量
 * @returns 总页数
 */
export function setTotalPage(len, size) {
	if (len % size === 0) {
		return parseInt(len / size)
	} else {
		return parseInt(len / size) + 1
	}
}

/**
 * 格式化select的选项
 * @param {Array} jsonData 
 * @returns 
 */
export function formSelectData(jsonData) {
	let res = []
	for (var key in jsonData) {
		res.push({ value: key.toString(), label: jsonData[key] })
	}
	return res
}


/**Table**************************************************************************************************************** */
export function setTableSorter(type, field) {
	if (type === 'ascend') {
		return { asc: field, desc: "" }
	} else if (type === 'descend') {
		return { asc: "", desc: field }
	} else {
		return { asc: "", desc: "" }
	}
}

/**根据key返回value************************************************************** */
export function setValByKey(list, typeId) {
	let val = ''
	for (let key in list) {
		// eslint-disable-next-line eqeqeq
		if (key == typeId) {
			val = list[key]
			break;
		}
	}
	return val
}
/**
 * 补0
 * @param {string} num 
 * @returns 
 */
export function addZero(num) {
	var t = (num + '').length,
		s = '';
	for (var i = 0; i < 2 - t; i++) {
		s += '0';
	}
	return s + num;
}


/******************************************************************************************** */
//多个值比较绝对值大小，取最大绝对值
export function getAbsMax(data) {
	let absList = data.map(d => Math.abs(d))
	let list = data.map(d => Math.abs(d))
	let s = list.sort(function (a, b) { return a - b })
	let index = absList.indexOf(s[s.length - 1])
	return data[index]
}
//获取数组最大值
export function getMax(data) {
	let tempData = data
	let s = data.sort(function (a, b) { return a - b })
	let index = tempData.indexOf(s[s.length - 1])
	return index === -1 ? 0.00 : tempData[index]
}
//获取数组最小值
export function getMin(data) {
	let tempData = data
	let s = data.sort(function (a, b) { return a - b })
	let index = tempData.indexOf(s[0])
	return index === -1 ? 0.00 : tempData[index]
}

export function getQuickIndex(list) {
	let greaterList = []
	let lessList = []
	if (list) {
		list.forEach(l => {
			if (l < 0) {
				lessList.push(l)
			} else if (l >= 0) {
				greaterList.push(l)
			}
		})
	}
	return [list.indexOf(getMax(greaterList)), list.indexOf(getMin(lessList))]
}

/********************************************************************** */
/**
 * 字元转ascii进制
 * @param {*} str 
 * @param {*} len 
 * @returns 
 */
export function stringToHex(str, len) {
	var val = [];
	for (var i = 0; i < len; i++) {
		if (i < str?.length) {
			val.push('0x' + str.charCodeAt(i).toString(16))
		} else {
			val.push("0x00")
		}

	}
	return val;
}

export function formPwByType(data, type) {
	let f1 = md5(data)
	return md5(f1 + type + f1)
}

/**
 * 向json数组中添加新数据，存在不添加
 */

export function addDataToJsonArray(jsonArray, newData) {
	let index = -1
	// 检查数组中是否已存在该数据
	index = jsonArray?.findIndex(item => item?.id === newData.id);
	if (index === -1) {
		// 如果不存在，则添加
		jsonArray.push(newData);
	}
	return jsonArray
}
/**
 * 根据某个属性去重
 * @param {} array 
 * @param {*} property 
 * @returns 
 */
export function uniqueArrayBasedOnProperty(array, property) {
	let unique = {};
	return array.filter((obj, index, self) => {
		const key = JSON.stringify(obj[property]);
		return unique.hasOwnProperty(key) ? false : (unique[key] = true);
	});
}

/**
 * 将jsonlist扁平化处理，并去重
 */
export function JsonArrayToNoRepeatList(JsonList) {
	// 将 JSON 对象的值转换为数组并合并
	let combinedArray = Object.values(JsonList).flat();
	// 使用 Set 去重
	// let uniqueArray = Array.from(new Set(combinedArray));
	let uniqueArray = uniqueArrayBasedOnProperty(combinedArray,'id');
	return uniqueArray
}

/**
 * 四舍五入保留两位小数后转换为百分比
 * @param {*} molecule 分子
 * @param {*} denominator 分母
 * @returns 
 */
export function getPercent(molecule, denominator) {
	let res = molecule / denominator
	return res.toFixed(2) * 100
}