import React, { Component } from "react";
import Slider from "react-slick";
import { isNoVal } from "../../utils/dataUtils"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less"
import intl from "react-intl-universal"
export default class MultipleItems extends Component {
  constructor(props) {
    super()
  }
  render() {
    const settings = {
      dots: true, //圆点显示（false隐藏）
      infinite: true, //无限的环绕内容
      autoplay: true, //自动播放，速度默认为（3000毫秒）
      speed: 500, //自动播放速度（毫秒）
      slidesToShow: 1, //在一帧中显示1张卡片
      slidesToScroll: 1 //一次滚动1张卡片
    };
    const { imageList, size, link } = this.props
    return (
      <div className='slider_image'>
        <Slider {...settings} >
          {imageList.map((item, index) => {
            return <div key={item.name}>
              <div className='slider_image-img-out' style={{ width: size.width, height: size.height }}>
                <img src={item.src} alt="" width={item.width} height={item.height} style={{ top: item?.top, left: item?.left }} />
              </div>
              <p className='slider_name'>{item.name}</p>
              {!isNoVal(link) && <a className='slider_a' rel='noopener noreferrer' href={link} target="_blank">{intl.get('MORE_PRODUCT')}</a>}

            </div>
          })}
        </Slider>
      </div>
    );
  }
}
