// import { ATRResultMap, getQuickATRResult } from '@/utils/report';
import intl from 'react-intl-universal';
import PlayActionVideo from "./components/playActionVideo"

export const preActionInfo = {
    attention: '',
    categoryId: "",
    description: "",
    effect: "",
    equipmentId: "",
    groupId: "",
    muscleGroup: "",
    name: '',
    partId: [],
    practice: "",
    remark: "",
    videoId: "",
    postId: "",
    duration:''
}
/**类别选项 */
export const classOptions = [
    {
        value: 0,
        key:'0',
        label: "ACTIONWAREHOUSE.CLASS1",
    },
    {
        value: 1,
        key:'1',
        label: "ACTIONWAREHOUSE.CLASS2",
    }
]

/**对象选项 */
export const objOptions = [
    {
        key: 0,
        value: 0,
        label: "ACTIONWAREHOUSE.OBJ1",
    },
    {
        key: 1,
        value: 1,
        label: "ACTIONWAREHOUSE.OBJ2",
    },
    {
        key: 2,
        value: 2,
        label: "ACTIONWAREHOUSE.OBJ3",
    },

]
/**位置选项 */
export const positionOptions = [
    {
        value: "0",
        label: "ACTIONWAREHOUSE.POSITION1",
    },
    {
        value: "1",
        label: "ACTIONWAREHOUSE.POSITION2",
    },
    {
        value: "2",
        label: "ACTIONWAREHOUSE.POSITION3",
    },
    {
        value: "3",
        label: "ACTIONWAREHOUSE.POSITION4",
    },
    {
        value: "4",
        label: "ACTIONWAREHOUSE.POSITION5",
    },
]

export const actionWarehouseSearch = [
    {
        name: 'categoryId',
        label: "ACTIONWAREHOUSE.CLASS", // 类别
        value: "",
        type: "select",
        options: classOptions,
        colSpan: 3,
        offset: 0
    },
    {
        name: 'groupId',
        label: 'ACTIONWAREHOUSE.OBJ', // 对象
        value: "",
        type: "select",
        options: objOptions,
        colSpan: 3,
        offset: 0
    },
    {
        name: 'partId',
        label: 'ACTIONWAREHOUSE.POSITION', // 训练部位
        value: "",
        type: "select",
        options: positionOptions,
        colSpan: 4,
        offset: 0
    },
    {
        name: 'muscleGroup',
        label: "ACTIONWAREHOUSE.GROUPS", // 训练肌群
        value: "",
        type: "input",
        options: [],
        colSpan: 3,
        offset: 0
    },
    {
        name: 'name',
        label: 'ACTIONWAREHOUSE.NAME',
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 3,
        offset: 0
    },

]

/**
 * 列表
 * @returns 
 */
export function actionWarehousesColumns() {
    return [
        {
            title: intl.get('ACTIONWAREHOUSE.CLASS'),
            dataIndex: 'categoryName',
            filterSearch: true,
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.OBJ'),
            dataIndex: 'groupName',
            filterSearch: true,
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.NAME'),
            dataIndex: 'name',
            filterSearch: true,
            sorter: false,
            width: 100,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.DESCRIPTION'),
            dataIndex: 'description',
            onFilter: (value: any, record: { name: string; }) => record.name.startsWith(value),
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.POSITION'),
            dataIndex: 'partName',
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.GROUPS'),
            dataIndex: 'muscleGroup',
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.VIDEO'),
            dataIndex: 'videoId',
            filterSearch: true,
            width: 80,
            align: 'center',
            listCol: true,
            render: (_: any, record: any) => {
                return <>
                    <PlayActionVideo record={record} />
                </>
            }
        },
    ];
}

/**
 * 新增表單
 * @returns 
 */
export function actionWarehouseForm() {
    return [
        // 类别
        {
            name: 'categoryId',
            key: "categoryId",
            label: "ACTIONWAREHOUSE.CLASS",
            value: "",
            type: "select",
            defaultValue: "",
            maxLength: 30,
            options: classOptions,
            message: "ACTIONWAREHOUSE.CLASS",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },
        //对象
        {
            name: 'groupId',
            key: "groupId",
            label: "ACTIONWAREHOUSE.OBJ",
            value: "",
            type: "select",
            defaultValue: "",
            maxLength: 30,
            options: objOptions,
            message: "ACTIONWAREHOUSE.OBJ",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },

        // 动作名称
        {
            name: 'name',
            key: "name",
            label: "ACTIONWAREHOUSE.NAME",
            value: "",
            type: "input",
            options: [],
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            message: "ACTIONWAREHOUSE.NAME",
            modal: ['add', 'edit'],
            maxLength:30,
        },
        // 描述
        {
            name: 'description',
            key: "description",
            label: "ACTIONWAREHOUSE.DESCRIPTION",
            value: "",
            type: "input",
            rules: [],
            options: [],
            message: "ACTIONWAREHOUSE.DESCRIPTION",
            modal: ['add', 'edit'],
            maxLength:100,
        },
        //所需器材
        {
            name: 'equipmentId',
            key: "equipmentId",
            label: "ACTIONWAREHOUSE.EQUIPMENT",
            value: "",
            type: "checkBox",
            rules: [],
            options: [{
                value: 0,
                label: "ACTIONWAREHOUSE.YOGA_MAT"
            }],
            message: "ACTIONWAREHOUSE.EQUIPMENT",
            modal: ['add', 'edit'],
        },
        //训练部位
        {
            name: 'partId',
            key: "partId",
            label: "ACTIONWAREHOUSE.POSITION",
            value: "",
            type: "checkBox",
            message: "ACTIONWAREHOUSE.POSITION",
            warn: "",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            options: positionOptions,
            modal: ['add', 'edit'],
        },
        //训练肌群
        {
            name: 'muscleGroup',
            key: "muscleGroup",
            label: "ACTIONWAREHOUSE.GROUPS",
            value: "",
            type: "textArea",
            rows: 4,
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            message: "ACTIONWAREHOUSE.GROUPS",
            warn: "",
            modal: ['add', 'edit'],
            placeholder: intl.get('ACTIONWAREHOUSE.GROUP_WARN'),
            maxLength:500,
        },
        //功效
        {
            name: 'effect',
            key: "effect",
            label: "ACTIONWAREHOUSE.EFFECT",
            value: "",
            type: "textArea",
            rows: 4,
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            message: "ACTIONWAREHOUSE.EFFECT",
            warn: "",
            modal: ['add', 'edit'],
            placeholder: "",
            maxLength:500,
        },
        //动作做法
        {
            name: 'practice',
            key: "practice",
            label: "ACTIONWAREHOUSE.PRACTICE",
            value: "",
            type: "textArea",
            rows: 4,
            rules: [],
            message: "ACTIONWAREHOUSE.PRACTICE",
            warn: "",
            modal: ['add', 'edit'],
            placeholder: "",
            maxLength:500,

        },
        //注意事项
        {
            name: 'attention',
            key: "attention",
            label: "ACTIONWAREHOUSE.WARNING",
            value: "",
            type: "textArea",
            rows: 4,
            rules: [],
            message: "ACTIONWAREHOUSE.WARNING",
            warn: "",
            modal: ['add', 'edit'],
            placeholder: "",
            maxLength:500, 
        },
        //动作视频
        {
            name: 'videoId',
            key: "videoId",
            label: "ACTIONWAREHOUSE.VIDEO",
            value: "",
            type: "video",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            message: "ACTIONWAREHOUSE.VIDEO",
            warn: "",
            modal: ['add', 'edit'],
        },
        //动作视频封面
        {
            name: 'postId',
            key: "postId",
            label: "ACTIONWAREHOUSE.VIDEO",
            value: "",
            type: "hidden",
            rules: [],
            message: "ACTIONWAREHOUSE.VIDEO",
            warn: "",
            modal: ['add', 'edit'],
        },
         //時長
         {
            name: 'duration',
            key: "duration",
            label: "ACTIONWAREHOUSE.VIDEO",
            value: "",
            type: "hidden",
            rules: [],
            message: "ACTIONWAREHOUSE.VIDEO",
            warn: "",
            modal: ['add', 'edit'],
        },
        //备注
        {
            name: 'remark',
            key: "remark",
            label: "ACTIONWAREHOUSE.MARK",
            value: "",
            type: "input",
            rules: [],
            message: "ACTIONWAREHOUSE.MARK",
            warn: "",
            modal: ['add', 'edit'],
        }
    ];
}
export const detailsContent = [

    // 训练部位
    {
        label: "ACTIONWAREHOUSE.POSITION",
        key: 'partName'
    },
    // 训练肌群
    {
        label: "ACTIONWAREHOUSE.GROUPS",
        key: 'muscleGroup'
    },
    //主要功效
    {
        label: "ACTIONWAREHOUSE.EFFECT",
        key: 'effect'
    },
    //动作做法
    {
        key: "practice",
        label: "ACTIONWAREHOUSE.PRACTICE",
    },
    //注意事项
    {
        key: "attention",
        label: "ACTIONWAREHOUSE.WARNING",
    }
]

