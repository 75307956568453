import { useEffect } from 'react'
import "./index.less"
import intl from 'react-intl-universal';

function LoginMode(props) {
    useEffect(() => {
    }, [])

    const { loginMode } = props
    return (
        <div className='loginMode'>
            <span
                className={loginMode === 'username' ? 'active-mode' : ''}
                onClick={() => props.changeLoginMode('username')}>
                {intl.get("LOGIN.USERNAME_MODE")}
            </span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span
                className={loginMode === 'phone' ? 'active-mode' : ''}
                onClick={() => props.changeLoginMode('phone')}
            >
                {intl.get("LOGIN.PHONE_MODE")}</span>
        </div>
    )
}
export default LoginMode