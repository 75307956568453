
import { SET_PATIENT_LIST, SET_PATIENT_SEARCH, SET_PATIENT_ORG, SET_QR_CANVAS } from '../ActionTypes'
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';
import { apiGetPatientsList, apiUpload, apiDelPatient, apiAddPatient, apiEditPatient, apiGet201Img, apiGet201Report } from "../../actions/api"
import { delSearchNull } from "../../utils/dataUtils"
import { message } from 'antd'
import intl from 'react-intl-universal';
import moment from "moment"
import { isHadLogin } from "./platform.js";

export const setPatientList = (data) => {
    return {
        type: SET_PATIENT_LIST,
        data
    }
}
export const setPatientSearch = (data) => {
    return {
        type: SET_PATIENT_SEARCH,
        data
    }
}

export const setPatientOrg = (data) => {
    return {
        type: SET_PATIENT_ORG,
        data
    }
}

export const setQRcanvas = (data) => {
    return {
        type: SET_QR_CANVAS,
        data
    }
}

// 重置 查询条件
export const resetPatientSearch = () => {
    return setPatientSearch({
        asc: "",
        desc: "",
        gradeName: "",
        name: "",
        page: 1,
        size: 20,
        studentId: ""
    })
}

export function* watchSwitchPatientSearch() {
    yield takeLatest('SET_PATIENT_SEARCH', getPatientsList);
}

export function* getPatientsList() {
    let search = delSearchNull(store.getState().patients.patientsSearch)
    if (isHadLogin()) {
        try {
            const data = yield call(apiGetPatientsList, search);
            const Elist = {
                list: data.rows,
                total: data.total
            };
            yield put(setPatientList(Elist))
        } catch {
            cancelled()
        } finally {

        }
    }

}

export function getPatientsListBySearch() {
    let search = delSearchNull(store.getState().patients.patientsSearch)
    return function (dispatch) {
        apiGetPatientsList(search)
            .then(data => {
                if (data.rows.length === 0 && search.page > 1) {
                    dispatch(setPatientSearch({ page: search.page - 1 }))
                } else {
                    const Elist = {
                        list: data.rows,
                        total: data.total
                    };
                    dispatch(setPatientList(Elist))
                }
            }).catch(() => { })
    }
}


export function uploadPatients(fileType, schoolId, data, options, modal) {
    return function (dispatch) {
        apiUpload(fileType, schoolId, data)
            .then(data => {
                dispatch(getPatientsListBySearch())
                options.onSuccess();
                modal.current.handleCancel()
                message.success(intl.get("PATIENT.UPDATED_SUCCESSFULLY") + data.total + intl.get("PATIENT.ARTICLE"));
            }).catch(() => { options.onError() })
    }

}

export function delPatientById(data) {
    return function (dispatch) {
        apiDelPatient(data)
            .then(() => {
                message.success(intl.get("ACTION.DEL_SUCCESS"))
                dispatch(getPatientsListBySearch())
            }).catch(() => { })
    }
}

export function addPatient(data, updateModal) {
    data.birthDate = moment(data.birthDate).format('YYYY-MM-DD')
    return function (dispatch) {
        apiAddPatient(data)
            .then(() => {
                message.success(intl.get("ACTION.ADD_SUCCESS"))
                dispatch(getPatientsListBySearch())
                updateModal.current.handleCancel()
            }).catch(() => { })
    }
}

export function editPatient(data, updateModal) {
    data.birthDate = moment(data.birthDate).format('YYYY-MM-DD')
    return function (dispatch) {
        apiEditPatient(data)
            .then(() => {
                message.success(intl.get("ACTION.EDIT_SUCCESS"))
                dispatch(getPatientsListBySearch())
                updateModal.current.handleCancel()
            }).catch(() => { })
    }
}



export function get201Img(data) {
    return apiGet201Img(data)
        .then(datas => {
            let list = []
            datas.rows.forEach(item => {
                if (item.fileData) list.push("data:image/png;base64," + item.fileData)
            })
            return list
        }).catch(() => { })
}

export function get201Report(data) {
    return apiGet201Report(data)
        .then(datas => {
            let list = []
            datas.rows.forEach(item => {
                if (item.fileData) list.push("data:image/png;base64," + item.fileData)
            })
            return list
        }).catch(() => { })
}
