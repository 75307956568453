import React from 'react';
import "./index.less"
import { notification } from 'antd';
import intl from 'react-intl-universal';
function ForgetPwd(props) {

  const openNotification = (placement) => {
    notification.info({
      message: intl.get("LOGIN.FORGET_PWD"),
      description: intl.get("LOGIN.FORGET_PWD_TXT"),
      placement,
      duration: 4,
    });
  };
  return (
    <div className='forget-pwd'>
      <div className='forget-pwd-txt' onClick={() => openNotification('top')}>{intl.get("LOGIN.FORGET_PWD")}</div>
    </div>
  );

}

export default ForgetPwd;
