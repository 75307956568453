import * as ActionTypes from '../ActionTypes';
import { btCodeSearch } from '../../pages/btCode/btCodeInfo';
import { reduxSearchData } from "../../utils/dataUtils"
function btCode(
    state = {
        btCodeList: { list: [], total: 0 },
        btSearch: reduxSearchData(btCodeSearch)
    },
    action
) {
    switch (action.type) {
        case ActionTypes.SET_BTCODE_LIST:
            return {
                ...state,
                btCodeList: action.data
            };
        case ActionTypes.SET_BTCODE_SEARCH:
            return {
                ...state,
                btSearch: { ...state.btSearch, ...action.data }
            };

        default:
            return state;
    }
}

export default btCode;
