import { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
// import Language from "../../components/language";
import "./index.less"
import SwiperImg from "../../components/swiperImg"
import scn101 from '../../static/images/spn101-device.png'
import pc from '../../static/images/scn101-PC.png'
import app from '../../static/images/spn101-app.png'
import { officialWebsite } from "../../config/version"
import LoginForm from "../../components/login/loginForm"
import Footer from '../../components/footer';
import intl from 'react-intl-universal';
import LoginMode from './loginMode';
import { frontEndVersion } from "@/config/version";



const imageList = [
  { src: scn101, name: "SPN101", width: '298', height: '121', top: '100px' },
  { src: app, name: "SPN101-APP", width: '205', height: '266', top: '0', left: '50px' },
  { src: pc, name: "SPN101-PC", width: '300', height: '190', top: '25px' }
]
function Login(props) {


  const [loginMode, setLoginMode] = useState('username')
  useEffect(() => {
  }, [])

  const changeLoginMode = (data) => {
    setLoginMode(data)
  }

  return (
    <>
      <div className='login'>
        <div className='login_center'>
          <div className='login_left'>
            <div className='login_swiper_img'>
              <SwiperImg imageList={imageList} size={{ width: '300px', height: '300px' }} link={officialWebsite} />
            </div>
          </div>
          <div className='login_right'>
            {/* INDEX.PLATFORM_NAME -- Scolionet脊柱体态监测管理平台 */}
            <div className='login_right_name'>{intl.get('INDEX.PLATFORM_NAME')}</div>
            <LoginForm loginMode={loginMode} />
            <LoginMode loginMode={loginMode} changeLoginMode={(data) => changeLoginMode(data)} />
            <div className='version'>{frontEndVersion}</div>
            {/* <div className='login_right_lang'><Language /></div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default withRouter(Login)