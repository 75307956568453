// /* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Captcha from 'react-captcha-code';
import "./index.less"
import { getPicCode } from "../../../actions/login"
import { formPwByType } from "../../../utils/dataUtils"
function VerifyCode(props) {
  const [captchaValue, setCaptchaValue] = useState('');

  useEffect(() => {
    props.loginStatus === false && changeVerifyCode()
  }, [props.loginStatus])


  const changeVerifyCode = () => {
    var timestamp = Date.parse(new Date());
    let data = {
      ttp: timestamp,
      mcode: formPwByType(timestamp, "+")
    }
    getPicCode(data).then(res => {
      setCaptchaValue(res);
    })
  }

  return (
    <Captcha
      height={props.height}
      code={captchaValue}
      onClick={() => changeVerifyCode()}
    />
  );

}

export default VerifyCode;
