import { initLanguage } from "../locales";
import intl from "react-intl-universal";
initLanguage();
// ! 由于之前的开发人员在代码中用到字典字段,改动流程复杂,所以在这里做一次初始化.尽量不要改动
// 此处的字典库独立于常规语音库,需要先进行语言初始化才能正常使用
// 未初始化时的错误警告
// react-intl-universal locales data "null" not exists.
const dictionary = {
  examStatusStr: {
    1: intl.get("DICTIONARY.INSPECTED"),
    2: intl.get("DICTIONARY.DECLINED"),
  },
  genderTypeStr: {
    0: "m",
    1: "f",
    2: "o",
  },
  userRoleTypeStr: {
    0: intl.get("DICTIONARY.SYSTEM_ADMINISTRATOR"),
    1: intl.get("DICTIONARY.TEST_CENTER_MANAGER"),
    2: intl.get("DICTIONARY.TESTING_CENTER_DOCTOR"),
    3: intl.get("DICTIONARY.PRODUCTION_BUSINESS"),
  },
  code: {
    400: intl.get("DICTIONARY.PARAMETER_ERROR"),
    1100: intl.get("DICTIONARY.LOGIC_ERROR_BACKEND"),
    1400: intl.get("DICTIONARY.PARAMETER_CONVERSION_ERROR"),
    1500: intl.get("DICTIONARY.SYSTEM_ERROR"),
    1501: intl.get("DICTIONARY.ILLEGAL_OPERATION"),
    2100: intl.get("DICTIONARY.LOGIC_ERROR_FRONT"),
    2400: intl.get("DICTIONARY.PARAMETER_ERROR_ILLOGICAL"),
    2401: intl.get("DICTIONARY.NOT_LOGGED_IN"),
    2403: intl.get("DICTIONARY.NO_PERMISSION"),
    3100: intl.get("DICTIONARY.LOGIC_ERROR"),
    8000: intl.get("DICTIONARY.SUCCESS"),
  },
  logLevel: {
    ERROR: intl.get("DICTIONARY.ERROR"),
    INFO: intl.get("DICTIONARY.INFO"),
    WARN: intl.get("DICTIONARY.WARN"),
  },
  projectStatusStr: {
    0: intl.get("DICTIONARY.NOT_STARTED"),
    1: intl.get("DICTIONARY.UNDERWAY"),
    2: intl.get("DICTIONARY.COMPLETED"),
  },
  exePrescriptionStatusStr: {
    0: intl.get("DICTIONARY.NOT_STARTED"),
    1: intl.get("DICTIONARY.UNDERWAY"),
    2: intl.get("DICTIONARY.UNCOMPLETED"),
    3: intl.get("DICTIONARY.COMPLETED"),
  },
  exameResultStr: {
    0: intl.get("DICTIONARY.NORMAL"),
    1: intl.get("DICTIONARY.LEVEL_1"),
    2: intl.get("DICTIONARY.LEVEL_2"),
    3: intl.get("DICTIONARY.LEVEL_3"),
    5: intl.get("DICTIONARY.BAD_POSTURE"),
  },
  codes: {
    400: intl.get("DICTIONARY.CODES.400"), // "参数错误",
    1100: intl.get("DICTIONARY.CODES.1100"), // "业务逻辑不符合要求(后端)!",
    1400: intl.get("DICTIONARY.CODES.1400"), // "参数转换错误",
    1404: intl.get("DICTIONARY.CODES.1404"), // "没有更新数据",
    1500: intl.get("DICTIONARY.CODES.1500"), // "系统错误，请联系管理员",
    1501: intl.get("DICTIONARY.CODES.1501"), // "非法操作(无权操作)",
    1502: intl.get("DICTIONARY.CODES.1502"), // "插入数据失败",
    1503: intl.get("DICTIONARY.CODES.1503"), // "更新数据失败",
    1504: intl.get("DICTIONARY.CODES.1504"), // "删除数据失败",
    2100: intl.get("DICTIONARY.CODES.2100"), // "业务逻辑不符合要求(前端)!",
    2400: intl.get("DICTIONARY.CODES.2400"), // "参数错误, 不符合业务逻辑要求",
    2401: intl.get("DICTIONARY.CODES.2401"), // "还未登录",
    2403: intl.get("DICTIONARY.CODES.2403"), // "没有操作权限",
    3100: intl.get("DICTIONARY.CODES.3100"), // "业务逻辑不符合要求",
    8000: intl.get("DICTIONARY.CODES.8000"), // "成功",
    24001301: intl.get("DICTIONARY.CODES.24001301"), // "参数不能为空!",
    24001302: intl.get("DICTIONARY.CODES.24001302"), // "学生ID不能为空!",
    24001303: intl.get("DICTIONARY.CODES.24001303"), // "受检单位ID不能为空!",
    24001304: intl.get("DICTIONARY.CODES.24001304"), // "项目ID不能为空!",
    24001305: intl.get("DICTIONARY.CODES.24001305"), // "项目不存在!",
    24001306: intl.get("DICTIONARY.CODES.24001306"), // "项目不是'进行中'!",
    24001307: intl.get("DICTIONARY.CODES.24001307"), // "此学生不存在",
    24001308: intl.get("DICTIONARY.CODES.24001308"), // "参数不能为空!",
    31001309: intl.get("DICTIONARY.CODES.31001309"), // "没有更新数据",
    31001310: intl.get("DICTIONARY.CODES.31001310"), // "全部上传成功",
    31001311: intl.get("DICTIONARY.CODES.31001311"), // "部分上传成功",
    24001401: intl.get("DICTIONARY.CODES.24001401"), // "受检单位Id不能为空!",
    24001402: intl.get("DICTIONARY.CODES.24001402"), // "系统管理员导入excel，需要提供机构ID!",
    24001403: intl.get("DICTIONARY.CODES.24001403"), // "读取Execl错误,请确认excel文档格式正确；系统不支持用WPS创建/编辑的Excel表格!",
    24001404: intl.get("DICTIONARY.CODES.24001404"), // "上传记录为空或模板不正确!",
    24001405: intl.get("DICTIONARY.CODES.24001405"), // "上传内容格式不对!",
    24001501: intl.get("DICTIONARY.CODES.24001501"), // "参数不能为空!",
    24001502: intl.get("DICTIONARY.CODES.24001502"), // "验证码不正确!",
    31001503: intl.get("DICTIONARY.CODES.31001503"), // "密码错误超过5次,请找管理员解锁!",
    21001504: intl.get("DICTIONARY.CODES.21001504"), // "登录失败,请检查用户名和密码!(若密码错误超过5次，账户会被锁定)",
    15001505: intl.get("DICTIONARY.CODES.15001505"), // "登录失败!",
    21001506: intl.get("DICTIONARY.CODES.21001506"), // "旧密码有误!",
    21001507: intl.get("DICTIONARY.CODES.21001507"), // "密码未更改!",
    31001508: intl.get("DICTIONARY.CODES.31001508"), // "重置密码失败,请重试!",
    24001509: intl.get("DICTIONARY.CODES.24001509"), // "手机号不能为空!",
    24001510: intl.get("DICTIONARY.CODES.24001510"), // "手机号只支持11位!",
    24001511: intl.get("DICTIONARY.CODES.24001511"), // "手机号不正确",
    24001512: intl.get("DICTIONARY.CODES.24001512"), // "非法请求",
    15001513: intl.get("DICTIONARY.CODES.15001513"), // "发送失败,请重试!",
    21001514: intl.get("DICTIONARY.CODES.21001514"), // "登录失败,请检查手机号是否已绑定!",
    15001515: intl.get("DICTIONARY.CODES.15001515"), // "登录失败!",
    31001516: intl.get("DICTIONARY.CODES.31001516"), // "手机号已使用!",
    24001517: intl.get("DICTIONARY.CODES.24001517"), // "非法请求",
    24001518: intl.get("DICTIONARY.CODES.24001518"), // "验证码不正确!",
    24001519: intl.get("DICTIONARY.CODES.24001519"), // "验证码已过期!",
    24001601: intl.get("DICTIONARY.CODES.24001601"), // "参数机构ID,机构名称不能为空！",
    24001602: intl.get("DICTIONARY.CODES.24001602"), // "机构不存在!",
    24001603: intl.get("DICTIONARY.CODES.24001603"), // "机构名称没有变化!",
    24001701: intl.get("DICTIONARY.CODES.24001701"), // "参数不能为空!",
    24001702: intl.get("DICTIONARY.CODES.24001702"), // "学生必要参数(学生ID, 受检单位ID)不能为空!",
    15001801: intl.get("DICTIONARY.CODES.15001801"), // "新增失败，确认名称是否已存在!",
    24001802: intl.get("DICTIONARY.CODES.24001802"), // "项目Id不能为空!",
    31001803: intl.get("DICTIONARY.CODES.31001803"), // "不能回退项目状态!",
    24001901: intl.get("DICTIONARY.CODES.24001901"), // "受检单位名称不能为空!",
    31001902: intl.get("DICTIONARY.CODES.31001902"), // "新增失败，确认名称是否已存在",
    21001903: intl.get("DICTIONARY.CODES.21001903"), // "机构Id,受检单位Id和受检单位名称不能为空!",
    24001904: intl.get("DICTIONARY.CODES.24001904"), // "受检单位不存在!",
    24001905: intl.get("DICTIONARY.CODES.24001905"), // "受检单位名称没有变化!",
    24002001: intl.get("DICTIONARY.CODES.24002001"), // "参数项目ID不能为空！",
    21002002: intl.get("DICTIONARY.CODES.21002002"), // "项目不存在或未关联受检单位!",
    24002003: intl.get("DICTIONARY.CODES.24002003"), // "参数年龄段不能为空！",
    24002004: intl.get("DICTIONARY.CODES.24002004"), // "参数年龄段要有三个年龄值！",
    24002005: intl.get("DICTIONARY.CODES.24002005"), // "参数年龄段数据格式有误！",
    31002101: intl.get("DICTIONARY.CODES.31002101"), // "手机号已使用!",
    24002102: intl.get("DICTIONARY.CODES.24002102"), // "参数不能为空！",
    24002103: intl.get("DICTIONARY.CODES.24002103"), // "用户名或密码不能为空！",
    15012104: intl.get("DICTIONARY.CODES.15012104"), // "手机号不正确!",
    24002105: intl.get("DICTIONARY.CODES.24002105"), // "角色不能为空!",
    24002106: intl.get("DICTIONARY.CODES.24002106"), // "在界面创建用户，角色ID不能为0",
    24002107: intl.get("DICTIONARY.CODES.24002107"), // "机构ID不能为空!",
    15012108: intl.get("DICTIONARY.CODES.15012108"), // "无创建系统用户权限!",
    15012109: intl.get("DICTIONARY.CODES.15012109"), // "无创建用户权限!",
    31002110: intl.get("DICTIONARY.CODES.31002110"), // "请检测用户名是否已存在!",
    15012112: intl.get("DICTIONARY.CODES.15012112"), // "用户不能为空!",
    15012113: intl.get("DICTIONARY.CODES.15012113"), // "机构ID不能为空!",
    15012114: intl.get("DICTIONARY.CODES.15012114"), // "手机号不正确!",
    15012115: intl.get("DICTIONARY.CODES.15012115"), // "非法修改!",
    24032116: intl.get("DICTIONARY.CODES.24032116"), // "无删除用户权限!"
    24001906: intl.get("DICTIONARY.CODES.24001906"), // "省/市/區不能為空"
    24001907: intl.get("DICTIONARY.CODES.24001907"), //"新舊數據沒有變化!"
  },
};

export default dictionary;
