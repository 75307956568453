import request from '@/utils/request';

type TAxiosResponseData<T = any> = {
    total: number;
    rows: T[];
    code: string;
    msg?: any;
    errorCode?: any;
    errorMsg?: any;
}

/**
 * 获取最新的检测记录101
 * @param params 
 * @returns 
 */
export const getUpToDateExamineHistory101 = (params: { patientId: string }) => {
    return request.get<any, TAxiosResponseData<API.TExamineHistoryRespData>>('/api/subions/exams/list', { params });
}

/**
 * 获取最新的检测记录201
 * @param params 
 * @returns blob文件流
 */
export const getUpToDateExamineHistory201 = (params: { patientId: string | null }) => {
    return request.get<any, { blob: Blob, headers: any }>('/api/subions/exams201/info', { params, responseType: 'blob' });
}

/**
 * 获取最新的检测记录201 - 按检测记录的条件查询
 * @param params 
 * @returns blob文件流
 */
export const getExamineHistory201ByEaxmsId = (params: { examsId: string | null }) => {
    return request.get<any, { blob: Blob, headers: any }>('/api/v1/exams/download/ultrasonic', { params, responseType: 'blob' });
}

/**
 * 获取最新的报告信息
 * @param params 
 * @returns 
 */
export const getUpToDateReportTemplateInfo = (params: { patientId: string | null }) => {
    return request.get<any, TAxiosResponseData<API.TGetReportTemplateInfoResponseData>>('/api/subions/template/info', { params });
}

/**
 * 获取常规报告数据
 * @param params 
 * @returns 
 */
export const getNormalReportData = (params: { examsId: number }) => {
    return request.get<any, TAxiosResponseData<API.TGetNormalReportDataReponse>>('/api/v1/exams/routine/report', { params });
}