import * as ActionTypes from '../ActionTypes';

function platform(
  state = {
    currentUser: {},
    commonInfo: {},
    modalStatus:false
  },
  action
) {
  switch (action.type) {
    case ActionTypes.SET_USER_INFO:
      return {
        ...state,
        currentUser: action.data
      };
    case ActionTypes.SET_COMMON_INFO:
      return {
        ...state,
        commonInfo: action.data
      };
    case ActionTypes.SET_MODAL_STATUS:
      return {
        ...state,
        modalStatus: action.data
      };

    default:
      return state;
  }
}

export default platform;
