import * as ActionTypes from '../ActionTypes';
import { caseInfo } from "../../pages/ExePrescription/exePrescriptionInfo"
import { exePrescriptionSearch } from "../../pages/ExePrescription/exePrescriptionInfo"
import {sysPrescriptionSearch} from "../../pages/ExePrescription/systemPrescription/systemPrescriptionInfo"
import { reduxSearchData } from "../../utils/dataUtils"
import {setPriPosList} from "../actions/exePrescription"
function exePrescription(
    state = {
        checkedExeList: setPriPosList(),
        checkedExeTableList: [],
        caseInfo: caseInfo,
        exePrescriptionSearch: reduxSearchData(exePrescriptionSearch),
        exePrescriptionList: { list: [], total: 0 },
        selectedPatToExe: '',
        selectUpdateExeId: "",
        /***************************************************************************************************** */
        systemExePrescriptionSearch: reduxSearchData(sysPrescriptionSearch),
        systemExePrescriptionList: { list: [], total: 0 },
    },
    action
) {
    switch (action.type) {
        case ActionTypes.SET_CHECKED_EXE_LIST:
            return {
                ...state,
                checkedExeList: action.data.JsonList,
                checkedExeTableList: action.data.tableList,
            };
        case ActionTypes.SET_CASE_INFO:
            return {
                ...state,
                caseInfo: action.data
            };
        case ActionTypes.SET_EXE_PRESCRIPTION_LIST:
            return {
                ...state,
                exePrescriptionList: action.data
            };
        case ActionTypes.SET_EXE_PRESCRIPTION_SEARCH:
            return {
                ...state,
                exePrescriptionSearch: { ...state.exePrescriptionSearch, ...action.data }
            };
        case ActionTypes.SET_ESELECTED_PAT_TO_EXE:
            return {
                ...state,
                selectedPatToExe: action.data
            };
        case ActionTypes.SET_ESELECTED_UPDATE_EXE_ID:
            return {
                ...state,
                selectUpdateExeId: action.data
            };
        /***************************************************************************************************** */
        case ActionTypes.SET_SYSTEM_EXE_PRESCRIPTION_LIST:
            return {
                ...state,
                systemExePrescriptionList: action.data
            };
        case ActionTypes.SET_SYSTEM_EXE_PRESCRIPTION_SEARCH:
            return {
                ...state,
                systemExePrescriptionSearch: { ...state.systemExePrescriptionSearch, ...action.data }
            };
        default:
            return state;
    }
}

export default exePrescription;
