
import { SET_EXAMS_LIST, SET_EXAMS_SEARCH } from '../ActionTypes';
import { /* apiGetExamsList, */ getExamineHistory } from "../../actions/api";
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';
import { getPatientSex } from '../../utils/dataUtils';
import { cloneDeep } from "lodash";
import { delSearchNull, getAbsMax, getQuickIndex, handleQuickData } from "../../utils/dataUtils";
import { getExameResultStr } from "../../redux/selecters/platform";
import { isHadLogin } from "./platform.js";
import { getATRImgRes } from "@/utils/examsUtils";
import { stones } from "../../pages/examine-history/examsInfo";

export const setExamsList = (data) => {
    return {
        type: SET_EXAMS_LIST,
        data
    }
}
export const setExamsSearch = (data) => {
    return {
        type: SET_EXAMS_SEARCH,
        data
    }
}

/*************************************************************************************** */
export function* watchSwitchExamsSearch() {
    yield takeLatest(SET_EXAMS_SEARCH, getExamsList);
}


export function* getExamsList() {
    const search = delSearchNull(store.getState().exams.examsSearch);
    if (isHadLogin()) {
        try {
            const data = yield call(getExamineHistory, search);
            const Elist = {
                list: formExamList(data.rows),
                total: data.total
            };
            yield put(setExamsList(Elist))
        } catch (error) {
            cancelled()
        } finally {

        }
    }
}


export function getExamsListBySearch() {
    const search = delSearchNull(store.getState().exams.examsSearch)
    return function (dispatch) {
        getExamineHistory(search)
            .then(data => {
                const Elist = {
                    list: formExamList(data.rows),
                    total: data.total
                };
                dispatch(setExamsList(Elist))
            }).catch(() => { })
    }
}

export function getExamsListByParams(search) {
    return getExamineHistory(search)
        .then(data => {
            const Elist = {
                list: formExamList(data.rows),
                total: data.total
            };
            return Elist
        }).catch(() => { })
}

export function formExamList(list) {

    let l = cloneDeep(list)
    l.map(item => {
        if (item.resultJson && typeof item.resultJson == 'string') {
            const examineDataList = JSON.parse(item.resultJson);
            item.res = getExameResultStr(store.getState())[item.resultId];
            item.data = examineDataList;
            item.quickData = handleQuickData(examineDataList);
            item.quickDataList = item.data[0].data;
            item.angL = getStoneClass(item.data[0].data?.slice(2), 0)
            item.angR = getStoneClass(item.data[0].data?.slice(2), 1)
            item.stoneInfoL = setRes0OR1(item.angL)
            item.stoneInfoR = setRes0OR1(item.angR)
        } else {
            item.data = '';
            item.res = '';
            item.quickData = '';
        }
        item.sex = getPatientSex(item.gender)
        return item
    })
    return l
}
/************************************************************************************ */
const getStoneClass = (quickDataAll, loc) => {
    let list = []
    let angleList = []
    quickDataAll?.forEach((d, index) => {
        if (((index + 1) % 3 === 0 && index > 1) || index === 49) {
            let max = getAbsMax([quickDataAll[index - 2], quickDataAll[index - 1], d])
            list.push(getATRImgRes(max))
            angleList.push(max)
        }
    })
    //    return setRes0OR1(stones[getQuickIndex(angleList)[loc]])
    return stones[getQuickIndex(angleList)[loc]]
}

function setRes0OR1(data) {
    if (data) {
        if (data.startsWith("T")) {
            return 0
        } else if (data.startsWith("L")) {
            return 1
        } else {
            return "-"
        }
    } else {
        return "-"
    }

}