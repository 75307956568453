import { getCurrentLocale, switchLanguage } from '../static/locales';

// 查询当前语言
export const queryCurrentLanguage = (list = []) => {
    // 查询当前语言
    const language = getCurrentLocale();
    // 查找当前语言的items子项
    const languageItem = list.find(item => item.key === language);
    // 返回查找项
    if (languageItem) return languageItem.label
    // 默认返回中文简体
    return '中文 / 简体';
}

// 语言切换
export const handleSwitchLanguage = (e) => {
    // 切换语言
    switchLanguage(e.key);
    // 重新加载
    window.location.reload();
};

// 默认导出
const lang = { queryCurrentLanguage, handleSwitchLanguage }
export default lang;