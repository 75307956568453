
import { SET_USER_INFO, SET_COMMON_INFO, SET_MODAL_STATUS } from '../ActionTypes';
import { getCurrentUser } from "../../redux/selecters/platform.js";
import store from '../../store';

export const initUser = (data) => {
    return {
        type: SET_USER_INFO,
        data
    }
}

export const setCommonInfo = (data) => {
    return {
        type: SET_COMMON_INFO,
        data
    }
}

export const setModalStatus = (data) => {
    return {
        type: SET_MODAL_STATUS,
        data
    }
}

export function getCommonInfo(dictionary) {
    return function (dispatch) {
        dispatch(setCommonInfo(dictionary))
        // apiCommon().then(resp => {
        //     dispatch(setCommonInfo(resp.data.data))
        // }).catch((error) => {
        //     console.warn(error.message)
        // })
    }
}

export function isHadLogin() {
    return getCurrentUser(store.getState()).username
}