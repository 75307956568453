import { SET_LOGS_LIST, SET_LOGS_SEARCH } from '../ActionTypes'
import { apiGetLogList } from "../../actions/api"
import { isHadLogin } from "./platform.js"
import { delSearchNull } from "../../utils/dataUtils"
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';

export const setLogsList = (data) => {
    return {
        type: SET_LOGS_LIST,
        data
    }
}
export const setLogsSearch = (data) => {
    return {
        type: SET_LOGS_SEARCH,
        data
    }
}

/*************************************************************************************** */
export function* watchSwitchLogsSearch() {
    yield takeLatest('SET_LOGS_SEARCH', getLogsList);
}


export function* getLogsList() {
    let search = delSearchNull(store.getState().logs.logsSearch)
    if (isHadLogin()) {
        try {
            const data = yield call(apiGetLogList, search);
            const Elist = {
                list: data.rows,
                total: data.total
            };
            yield put(setLogsList(Elist))
        } catch {
            cancelled()
        } finally {

        }
    }
}


export function getLogsListBySearch() {
    let search = delSearchNull(store.getState().logs.logsSearch)
    return function (dispatch) {
        apiGetLogList(search)
            .then(data => {
                const Elist = {
                    list: data.rows,
                    total: data.total
                };
                dispatch(setLogsList(Elist))
            }).catch(() => { })
    }
}
