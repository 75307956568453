// 组件
import { Layout } from 'antd';
import Header from './components/Header';
import SiderMenu from './components/SiderMenu';
import RouterView from './components/RouterView';

// 路由
import routerConfig from '../router';

// 样式
import style from './index.module.less';

// 状态仓库
import { getCurrentUser } from '../redux/selecters/platform.js';
import { useStore } from 'react-redux';

// 钩子
import { useEffect, useState } from 'react';

const { content } = style;

// 布局组件
const { Sider, Content } = Layout;

/**
 * #### 菜单组件
 * * 由于执行顺序的问题, 登录后路由访问早于菜单创建, 所以需要进行数据监听
 * * 监听数据 store/role -- 因为登录时会生成角色id并存储在store, 路由根据角色id完成路由创建
 * * 因此 监听 store/role 可以在其变化后再开始创建路由并在此组件内完成菜单创建和渲染
 * @returns 
 */
const MainLayout = () => {
    // 获取 store 
    const store = useStore();
    // 定义 角色容器
    const [role, setRole] = useState(getCurrentUser(store.getState()));
    // 定义 路由数据表容器
    const [routers, setRouters] = useState<API.TRouterConfig[]>([]);


    // 获取 角色数据
    useEffect(() => {
        setRole(getCurrentUser(store.getState()));
        // 监听 store
    }, [store]);


    // 获取 路由数据
    useEffect(() => {
        setRouters(routerConfig());
        // 监听 角色数据
    }, [role]);


    // 渲染
    return <Layout>
        <Header />
        <Layout>
            <Sider>
                {/* 菜单 */}
                <SiderMenu routers={routers} />
            </Sider>
            <Content className={content}>
                {/* 主模块的二级路由入口 */}
                <RouterView routers={routers} />
            </Content>
        </Layout>
    </Layout>
}


export default MainLayout;
