import { SET_BTCODE_LIST, SET_BTCODE_SEARCH } from '../ActionTypes'
import { apiCreateBlueCode, apiGetBlueCodeList, apidownloadBtCode } from "../../actions/api"
import { message } from 'antd'
import { delSearchNull } from "../../utils/dataUtils"
import { downloadBtCodeZip } from "../../utils/downloadUtils"
import intl from 'react-intl-universal';
import store from '../../store';
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import { isHadLogin } from "./platform.js"
export const setBtCodeList = (data) => {
    return {
        type: SET_BTCODE_LIST,
        data
    }
}

export const setBtCodeSearch = (data) => {
    return {
        type: SET_BTCODE_SEARCH,
        data
    }
}

/*************************************************************************************** */
export function* watchSwitchBtCodeSearch() {
    yield takeLatest('SET_BTCODE_SEARCH', getBlueCodeList);
}


export function* getBlueCodeList() {
    let search = delSearchNull(store.getState().btCode.btSearch)
    if (isHadLogin()) {
        try {
            const data = yield call(apiGetBlueCodeList, search);
            const Elist = {
                list: data.rows,
                total: data.total
            };
            yield put(setBtCodeList(Elist))
        } catch {
            cancelled()
        } finally {

        }
    }
}

export function getBlueCodeListBySearch() {
    let search = delSearchNull(store.getState().btCode.btSearch)
    return function (dispatch) {
        apiGetBlueCodeList(search)
            .then(data => {
                const Elist = {
                    list: data.rows,
                    total: data.total
                };
                dispatch(setBtCodeList(Elist))
            }).catch(() => { })
    }
}

export function createBlueCode(data) {
    return function (dispatch) {
        apiCreateBlueCode(data)
            .then(() => {
                message.success(intl.get("ACTION.ADD_SUCCESS"))
                // dispatch(getProjectsListBySearch('search'))
                // updateModal.current.handleCancel();
            }).catch(() => { })
    }
}

export function downloadBtCode() {
    apidownloadBtCode()
        .then(res => {
            downloadBtCodeZip(res.data, res.msg, res.filename)
        }).catch(() => { })
}
