import * as ActionTypes from '../ActionTypes';
import { actionWarehouseSearch } from '../../pages/actionWarehouse/actionWarehouseInfo';
import { reduxSearchData } from "../../utils/dataUtils"

function actionWarehouses(
    state = {
        actionWarehousesList: { list: [], total: 0 },
        actionWarehousesSearch: reduxSearchData(actionWarehouseSearch)
    },
    action
) {
    switch (action.type) {
        case ActionTypes.SET_ACTION_WAREHOUSES_LIST:
            return {
                ...state,
                actionWarehousesList: action.data
            };
        case ActionTypes.SET_ACTION_WAREHOUSES_SEARCH:
            return {
                ...state,
                actionWarehousesSearch: { ...state.actionWarehousesSearch, ...action.data }
            };

        default:
            return state;
    }
}

export default actionWarehouses;
