import { apiLogin, apiLogout, apiEditPwd, apiGetIp, apiSendSms, apiloginSms, apiGetPicCode } from "./api";
import { initUser, getCommonInfo } from "../redux/actions/platform";
import { getAllOrgsList, getAllSchoolList } from "../redux/actions/orgs";
import { reduxSearchData } from "../utils/dataUtils";
import { getRoleLimits } from "../utils/roleUtils";
import { setPatientSearch } from "../redux/actions/patients.js";
import { setProjectsSearch } from "../redux/actions/projects.js";
import { setExamsSearch } from "../redux/actions/exams.js";
import { setUsersSearch } from "../redux/actions/users.js";
import { projectSearch } from "../pages/project/utils";
import { patientSearch } from "../pages/patientsList/patientListInfo";
import { examineSearch } from "../pages/examine-history/examsInfo";
import { logsSearch } from "../pages/logs/logInfo";
import { userSearch } from "../pages/users/userInfo";
import { message } from "antd";
import intl from 'react-intl-universal';
import { setLogsSearch } from "../redux/actions/logs";
import mitt from '../utils/mitt';
import dictionary from '../static/dictionary'; // 导入字典
import store from '../store';
// 报告配置接口
import { getReportConfigRequest } from '@/api';
import { setReportInfoAction } from '@/redux/actions/report.ts';

// 派发获取字典动作
mitt.on("switchLanguage", () => store.dispatch(getCommonInfo(dictionary)));

export function login(history, data) {
  return async function (dispatch) {
    return apiLogin(data)
      .then(data => {
        loginSuccessAction(dispatch, data, history);
        return data.rows[0];
      }).catch(() => false)
  }
}

export function loginBySms(history, data) {
  return function (dispatch) {
    apiloginSms(data)
      .then(data => {
        loginSuccessAction(dispatch, data, history)
      }).catch(error => { })
  }
}

export function logoutAction(history) {
  return function (dispatch) {
    apiLogout()
      .then(() => {
        dispatch(initUser({}));
        message.success(intl.get('LOGIN.LOGOUT_SUCCESS')); // 登出
        history.push("/login");
      })
      .catch(() => { history.push("/login") })
  }
}

export async function sendSms(data) {
  return apiSendSms(data)
    .then(() => {
      message.success(intl.get("LOGIN.SENT_SUCCESSFULLY")); // 发送成功
      return true
    })
    .catch(() => false)
}

export async function getPicCode(data) {
  return apiGetPicCode(data)
    .then(data => data.msg)
    .catch(() => false)
}


function loginSuccessAction(dispatch, data, history) {
  message.success(intl.get('LOGIN.LOGIN_SUCCESS'))
  let role = data.rows[0].roleType
  dispatch(setPatientSearch(reduxSearchData(patientSearch)))
  dispatch(setProjectsSearch(reduxSearchData(projectSearch)))
  dispatch(setExamsSearch(reduxSearchData(examineSearch)))

  // 获取报告配置
  getReportConfigRequest().then((result) => {
    // 缓存报告配置到redux
    dispatch(setReportInfoAction(result.rows[0]))
  }).catch((err) => {
    console.log('err', err);
  });


  if (role !== 2) {
    dispatch(setLogsSearch(reduxSearchData(logsSearch)))
    dispatch(setUsersSearch(reduxSearchData(userSearch)))
  }
  dispatch(initUser(formdataUser(data.rows[0])))
  // dispatch(getCommonInfo(dictionary))
  if (role === 0) {
    dispatch(getAllOrgsList())
    history.push('/mainPage/organization')
  } else if (role === 3) {
    history.push('/mainPage/btCodeDownload')
  } else {
    dispatch(getAllSchoolList())
    history.push('/mainPage/patientsList')
  }
}


export function editPwd(data, history) {
  delete data.confirm
  apiEditPwd(data)
    .then(() => {
      message.success(intl.get("ACTION.UPDATE_PWD_SUCCESS"));
      history.push("/");
    })
    .catch(() => { })
}

export function getIp() {
  return apiGetIp().then(data => data.msg).catch(() => { })
}


function formdataUser(data) {
  let res = data
  res.roleId = res.roleType
  res.permissions = getRoleLimits(res.roleId)
  return res
}