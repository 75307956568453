import React, { useState } from 'react';
import "./index.less"
import intl from 'react-intl-universal';
import { Spin, message } from "antd"
import { sendSms } from "../../actions/login.js"
import { formPwByType } from "../../utils/dataUtils"
import { regPhone } from "../../utils/regUtils"
const Countdown = (props) => {
    const maxSec = 60
    const [seconds, setSeconds] = useState(maxSec);
    const [loading, setLoading] = useState(false);

    const sendCode = () => {
        var timestamp = Date.parse(new Date());
        setLoading(true)
        props.setPhoneDis(true)
        let res = {
            tel: props.userPhone,
            ttp: timestamp,
            mcode: formPwByType(props.userPhone + timestamp, "+")
        }
        sendSms(res).then(res => {
            if (res) {
                const timer = setInterval(() => {
                    setLoading(false)
                    setSeconds((prevSeconds) => {
                        if (prevSeconds > 0) {
                            return prevSeconds - 1
                        } else {
                            clearInterval(timer);
                            props.setPhoneDis(false)
                            return maxSec
                        }
                    });
                }, 1000);
            } else {
                setLoading(false)
                props.setPhoneDis(false)
                console.log("发送失败")
            }
        }).catch(error => { })
    }

    return (
        <div className='count-down'>
            <Spin spinning={loading}>
                {seconds === maxSec || seconds === 0 ?
                    <span onClick={
                        regPhone.test(props.userPhone) ?
                            () => sendCode() :
                            () => { message.error(intl.get("LOGIN.ENTER_MOBILE_NUMBER")) }
                    }
                        className='count-down-send'>{intl.get("LOGIN.SEND_CODE")}</span>
                    : <span>{intl.get("LOGIN.RESEND_CODE", { name: seconds })}</span>
                }
            </Spin>
        </div>
    );
};

export default Countdown;