import QRCode from 'qrcode.react'
import React from "react";
import './index.less'
function QEcode(props) {
    return (
        <div className='QEcode-out'>
            <div className='QEcode-inner' style={{ width: props.QRParams.size + 'px', height: props.QRParams.size + 'px' }} >
                <QRCode
                    value={props.data}// 生成二维码的内容
                    size={props.QRParams.size} // 二维码的大小
                    fgColor="#000000" // 二维码的颜色
                    level="L"
                />
            </div>
        </div>
    )
}
export default QEcode