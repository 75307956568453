import intl from 'react-intl-universal';
import { getPatientSex } from "../../utils/dataUtils";
import { Tag } from 'antd';
import { getExePrescriptionStatusStr } from "../../redux/selecters/platform.js"
import store from '../../store';
export const exePrescriptionSearch = [
    {
        name: 'patientName',
        label: 'PATIENT.NAME',
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 3,
        offset: 0
    },
    {
        name: 'gender',
        label: "PATIENT.SEX", // 性别
        value: "",
        type: "select",
        options: [{
            value: "m",
            label: "PATIENT.MALE"
        }, {
            value: "f",
            label: "PATIENT.FEMALE"
        }, {
            value: "o",
            label: "PATIENT.OTHER_SEX"
        }
        ],
        colSpan: 3,
        offset: 0
    },
    {
        name: 'isIssued',
        label: 'EXE_PRESCRIPTION.IS_OPEN',
        value: "",
        type: "select",
        defaultValue: "",
        options: [{
            value: 0,
            label: "EXE_PRESCRIPTION.UNOPEN"
        }, {
            value: 1,
            label: "EXE_PRESCRIPTION.OPENED"
        }
        ],
        colSpan: 3,
        offset: 0
    },
    {
        name: 'status',
        label: 'EXE_PRESCRIPTION.STATE',
        value: "",
        type: "select",
        defaultValue: "",
        options: [],
        colSpan: 3,
        offset: 0
    },
]

const tagColor = ['gray', 'blue', 'red', 'green']
const isOpenTagColor = ['red', 'green']
/**
 * 列表
 * @returns 
 */
export function exePrescriptionColumns() {
    return [
        {
            title: intl.get('PATIENT.STUDENT_ID'),
            dataIndex: 'studentId',
            filterSearch: true,
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
            onCell: (row: any) => ({ rowSpan: row.rowSpan || 0 })
        },
        {
            title: intl.get('PATIENT.NAME'),
            dataIndex: 'patientName',
            filterSearch: true,
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
            onCell: (row: any) => ({ rowSpan: row.rowSpan || 0 })
        },
        {
            title: intl.get('PATIENT.SEX'),
            dataIndex: 'gender',
            filterSearch: true,
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
            render: (_, record) => <span>{getPatientSex(record.gender)}</span>,
            onCell: (row: any) => ({ rowSpan: row.rowSpan || 0 })
        },
        {
            title: intl.get('PATIENT.AGE'),
            dataIndex: 'age',
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
            onCell: (row: any) => ({ rowSpan: row.rowSpan || 0 })
        },
        {
            title: intl.get('EXE_PRESCRIPTION.IS_OPEN'),
            dataIndex: 'isIssuedName',
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
            render: (_: any, record) => {
                const index = +(record.isIssued || 0)
                return <Tag className={index + ''} color={isOpenTagColor[index]}>
                    {record.isIssuedName}
                </Tag>
            },
            onCell: (row: any) => ({ rowSpan: row.rowSpan || 0 })
        },
        {
            title: intl.get('EXE_PRESCRIPTION.NAME'),
            dataIndex: 'name',
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.ACTION_PROJECT'),
            dataIndex: 'actionCount',
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
            render: (_, record) => <span>{record.isIssued ? intl.get('EXE_PRESCRIPTION.ACTION_NUM', { num: record.actionCount }) : ''}</span>,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.START_TIME'),
            dataIndex: 'startTime',
            sorter: false,
            width: 100,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.END_TIME'),
            dataIndex: 'endTime',
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.STATE'),
            dataIndex: 'status',
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
            render: (_, record) => {
                const index = +(record.status || 0);
                return <span>{record.isIssued ? <Tag className={index + ''} color={tagColor[index]}>
                    {getExePrescriptionStatusStr(store.getState())[index]}
                </Tag> : ""}</span>
            },
        },
    ];
}

/**
 * 动作介绍
 */
export const detailsContent = [
    // 对象
    {
        label: "ACTIONWAREHOUSE.OBJ",
        key: 'groupName'
    },
    //名称
    {
        label: "ACTIONWAREHOUSE.NAME",
        key: 'name'
    },
    //描述
    {
        key: "description",
        label: "ACTIONWAREHOUSE.DESCRIPTION",
    },
    //训练部位
    {
        key: "partName",
        label: "ACTIONWAREHOUSE.POSITION",
    },
    //动作做法
    {
        key: "practice",
        label: "ACTIONWAREHOUSE.PRACTICE",
    }
]

export const caseOptions = [{
    key: 0,
    value: 0,
    label: 'EXE_PRESCRIPTION.CUSTOM',
    disabled: false
},
{
    key: 1,
    value: 1,
    label: 'EXE_PRESCRIPTION.SYSTEM_PRE',
    disabled: true
}
]

export const timeOptions = [{
    key: 7,
    value: 7,
    label: 'TIME.WEEK'
},
{
    key: 14,
    value: 14,
    label: 'TIME.TWO_WEEK'
},
{
    key: 30,
    value: 30,
    label: 'TIME.MONTH'
},
{
    key: 60,
    value: 60,
    label: 'TIME.TWO_MONTH'
}
]

export function caseExeColums() {
    return [
        {
            title: intl.get('COMMON.INDEX'),
            dataIndex: 'index',
            width: 60,
            render: (_, __, index) => index + 1,
        },
        {
            title: intl.get('ACTIONWAREHOUSE.NAME'),
            dataIndex: 'name',
            width: 100
        }
    ]
}

export function progressColums() {
    return [
        {
            title: intl.get('COMMON.INDEX'),
            dataIndex: 'index',
            width: 60,
            render: (_, __, index) => index + 1,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.TRAIN_DATE'),
            dataIndex: 'time',
            width: 100
        },
        {
            title: intl.get('EXE_PRESCRIPTION.TRAIN_TIME'),
            dataIndex: 'trainedDuration',
            width: 100
        },
        {
            title: intl.get('EXE_PRESCRIPTION.TRAIN_ACTIONS'),
            dataIndex: 'trainedNum',
            width: 100
        },

    ]
}


export const caseInfo = {
    actionPlan: 0,
    name: "",
    daysLen: 7,
    startTime: "",
    endTime: ""
}

export const sysCaseInfo = {
    name: "",
    description:'',
    postId: "",
    postUrl:""
}