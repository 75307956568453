import * as ActionTypes from '../ActionTypes';
import { userSearch } from '../../pages/users/userInfo';
import { reduxSearchData } from "../../utils/dataUtils"

function exams(
    state = {
        usersList: { list: [], total: 0 },
        usersSearch: reduxSearchData(userSearch),
        currentUser: {}
    },
    action
) {
    switch (action.type) {
        case ActionTypes.SET_USERS_SEARCH:
            return {
                ...state,
                usersSearch: { ...state.usersSearch, ...action.data }
            };
        case ActionTypes.SET_USERS_LIST:
            return {
                ...state,
                usersList: { ...state.usersList, ...action.data }
            };
        case ActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: { ...action.data }
            };
        default:
            return state;
    }
}

export default exams;
