import intl from 'react-intl-universal';
import { getRoleNameByRoleId } from "../../utils/roleUtils"
export const logsSearch = [
    {
        name: 'username',
        label: "USERS.USERNAME",
        value: "",
        type: "input",
        options: [],
        colSpan: 3,
        offset: 0
    }, 
    {
        name: 'orgname',
        label: "USERS.ORGNAME",
        value: "",
        type: "input",
        options: [],
        colSpan: 3,
        offset: 1
    }, 
    {
        name: 'role',
        label: "USERS.ACTOR",
        value: "",
        type: "select",
        options: [],
        colSpan: 3,
        offset: 1
    },

]
export function logColumns() {
    return [
        {
            title: intl.get('USERS.USERNAME'),
            dataIndex: 'username',
            filterSearch: true,
            width: 100,
            sorter: true,
            align: 'center'
        },
        {
            title: intl.get('USERS.ORGNAME'),
            dataIndex: 'orgname',
            sorter: true,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('USERS.ACTOR'),
            dataIndex: 'role',
            sorter: true,
            width: 100,
            align: 'center',
            render: (_, record) => <div>{getRoleNameByRoleId(record.role)}</div>
        },
        {
            title: intl.get('LOGS.MODEL'),
            dataIndex: 'model',
            sorter: true,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('LOGS.KEY_POINTS'),
            dataIndex: 'keypoint',
            sorter: true,
            width: 100,
            align: 'center'
        }, {
            title: intl.get('LOGS.KEY_MSG'),
            dataIndex: 'keymsg',
            // sorter: true,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('LOGS.LEVEL'),
            dataIndex: 'level',
            // sorter: true,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('LOGS.OPTYPE'),
            dataIndex: 'optype',
            // sorter: true,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('LOGS.OPTIME'),
            dataIndex: 'optime',
            sorter: true,
            width: 100,
            align: 'center'
        },
    ];
}
