import * as ActionTypes from '../ActionTypes';
import { logsSearch } from '../../pages/logs/logInfo';
import { reduxSearchData } from "../../utils/dataUtils"

function exams(
    state = {
        logsList: { list: [], total: 0 },
        logsSearch: reduxSearchData(logsSearch)
    },
    action
) {
    switch (action.type) {
        case ActionTypes.SET_LOGS_LIST:
            return {
                ...state,
                logsList: action.data
            };
        case ActionTypes.SET_LOGS_SEARCH:
            return {
                ...state,
                logsSearch: { ...state.logsSearch, ...action.data }
            };

        default:
            return state;
    }
}

export default exams;
