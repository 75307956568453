import React, { Suspense } from 'react';
import './App.css';
import Login from './pages/login/index.js';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import zhHK from 'antd/es/locale/zh_HK';
import Cookies from 'js-cookie';
import ExamsByPhone from "@/pages/examsByPhone";
import Error from '@/pages/error';

import MainLayout from '@/layout';
import intl from 'react-intl-universal';

const DataLargeScreen = React.lazy(() => import('@/pages/dataLargeScreen'));
const ReportSearch = React.lazy(() => import('@/pages/webview/report-search'));
const DownloadQuickReprot = React.lazy(() => import('@/pages/webview/download-quick-report'));
const ScanCode = React.lazy(() => import('@/pages/webview/scan-code'));
const AddPatientForm = React.lazy(() => import('@/pages/webview/add-patient-form'));

function App() {
  const lang = Cookies.get('lang');
  const locale = lang === 'zh' ? zhCN : lang === 'hk' ? zhHK : enUS;
  return (
    <ConfigProvider locale={locale}>
      <div className="App">

        <Router>
          <Switch>
            <Route path='/mainPage' component={MainLayout} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/examsByPhone' component={ExamsByPhone} /> {/* 公众号手机查询h5页面 */}


            {/* 此处 props 携带有路由相关参数和方法, 一并传递到 DataLargeScreen 组件 */}
            <Route path='/dataLargeScreen' component={(props: any) => {
              return <>
                <Suspense fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                  {/* 数据大屏 */}
                  <DataLargeScreen {...props} />
                </Suspense>
              </>
            }} />


            {/* 此处 props 携带有路由相关参数和方法, 一并传递到 ScanCode 组件 */}
            <Route path='/webview/scan-code' component={(props: any) => {
              return <>
                <Suspense fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                  {/* 数据大屏 */}
                  <ScanCode {...props} />
                </Suspense>
              </>
            }} />


            {/* 此处 props 携带有路由相关参数和方法, 一并传递到 ReportSearch 组件 */}
            <Route path='/webview/report-search' component={(props: any) => {
              return <>
                <Suspense fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                  {/* 数据大屏 */}
                  <ReportSearch {...props} />
                </Suspense>
              </>
            }} />


            {/* 此处 props 携带有路由相关参数和方法, 一并传递到 DownloadQuickReprot 组件 */}
            <Route path='/webview/download-quick-report' component={(props: any) => {
              return <>
                <Suspense fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                  {/* 数据大屏 */}
                  <DownloadQuickReprot {...props} />
                </Suspense>
              </>
            }} />


            {/* 此处 props 携带有路由相关参数和方法, 一并传递到 AddPatientForm 组件 */}
            <Route path='/webview/add-patient-form' component={(props: any) => {
              return <>
                <Suspense fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                  {/* 添加受检人表单 */}
                  <AddPatientForm {...props} />
                </Suspense>
              </>
            }} />


            {/* 默认页面 重定向到 -- 登录页(所有角色共有页面) */}
            <Redirect exact from='/' to='/login' />

            {/* 错误页面 */}
            <Route path='*' component={Error} />
          </Switch>
        </Router>
      </div>
    </ConfigProvider>

  );
}

export default App
