import { getPatientAge, getPatientSex } from "../../utils/dataUtils";
import { validatePhone } from "../../utils/regUtils"
import intl from 'react-intl-universal';



// 受检人初始化信息
export const prePatientInfo = {
    name: '',
    studentId: "",
    gender: "",
    schoolId: "",
    gradeName: "",
    clazzName: "",
    tel: ""
}

// 页面搜索栏信息
export const patientSearch = [
    {
        name: 'schoolId',
        label: "PATIENT.SCHOOL",
        value: undefined,
        type: "select",
        options: [],
        colSpan: 3,
        offset: 0
    },
    {
        name: 'gradeName',
        label: "PATIENT.PRIMARY",
        value: "",
        type: "input",
        options: [],
        colSpan: 2,
        offset: 0
    },
    {
        name: 'clazzName',
        label: "PATIENT.CLASS",
        value: "",
        type: "input",
        options: [],
        colSpan: 2,
        offset: 0
    },
    {
        name: 'studentId',
        label: "PATIENT.STUDENT_ID",
        value: undefined,
        type: "input",
        options: [],
        colSpan: 2,
        offset: 0
    },
    {
        name: 'name',
        label: "PATIENT.NAME",
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 2,
        offset: 0,
        maxLength: 50
    },
    {
        name: 'tel',
        label: "PATIENT.TEL",
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 4,
        offset: 0,
        maxLength: 50
    },
]

// 页面渲染表格
export function patientColumns() {
    return [
        {
            title: intl.get('PATIENT.SCHOOL'),
            dataIndex: 'schoolName',
            filterSearch: true,
            width: 100,
            align: 'center',
        },
        {
            title: intl.get('PATIENT.STUDENT_ID'),
            dataIndex: 'studentId',
            filterSearch: true,
            width: 200,
            align: 'center'
        },
        {
            title: intl.get('PATIENT.NAME'),
            dataIndex: 'name',
            onFilter: (value, record) => record.name.startsWith(value),
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('PATIENT.BIRTHDATA'),
            dataIndex: 'birthday',
            render: (_, record) => <span>{record.birthDate}</span>,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('PATIENT.AGE'),
            dataIndex: 'age',
            render: (_, record) => <span>{getPatientAge(record.birthDate)}</span>,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('PATIENT.SEX'),
            dataIndex: 'gender',
            filterSearch: true,
            render: (_, record) => <span>{getPatientSex(record.gender)}</span>,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('PATIENT.PRIMARY'),
            dataIndex: 'gradeName',
            filterSearch: true,
            width: 100,
            align: 'center'
        }, {
            title: intl.get('PATIENT.CLASS'),
            dataIndex: 'clazzName',
            filterSearch: true,
            width: 100,
            align: 'center'
        },
        {
            title: intl.get('PATIENT.TEL'),
            dataIndex: 'tel',
            filterSearch: true,
            width: 100,
            align: 'center'
        },

    ];
}

// 弹窗表单
export function patientForm() {
    return [
        // 名字
        {
            name: 'name',
            key: "name",
            label: "PATIENT.NAME",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 15,
            message: "PATIENT.NAME_MSG",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },
        // 学生id
        {
            name: 'studentId',
            key: "studentId",
            label: "PATIENT.PATIENTID",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 30,
            message: "PATIENT.PATIENTID",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },
        // 性别
        {
            name: 'gender',
            key: "gender",
            label: "PATIENT.SEX",
            value: "",
            type: "radio",
            message: "PATIENT.SEX",
            warn: "",
            rules: [{
                required: true,
                message: intl.get("COMMON.IS_REQUIRED"),
            },],
            options: [{
                value: "m",
                label: "PATIENT.MALE"
            }, {
                value: "f",
                label: "PATIENT.FEMALE"
            }, {
                value: "o",
                label: "PATIENT.OTHER_SEX"
            }
            ],
            modal: ['add', 'edit'],
        },
        // 学习id
        {
            name: 'schoolId',
            key: "schoolId",
            label: "PATIENT.SCHOOL",
            value: "",
            type: "select",
            options: [],
            message: "PATIENT.SCHOOL",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add'],
        },
        // 学习名字
        {
            name: 'schoolName',
            key: "schoolName",
            label: "PATIENT.SCHOOL",
            value: "",
            type: "text",
            options: [],
            message: "PATIENT.SCHOOL",
            modal: ['edit'],
        },
        // 年级
        {
            name: 'gradeName',
            key: "gradeName",
            label: "PATIENT.PRIMARY",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 15,
            message: "PATIENT.PRIMARY",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },
        // 班级
        {
            name: 'clazzName',
            key: "clazzName",
            label: "PATIENT.SECONDARY",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 15,
            message: "PATIENT.SECONDARY",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },
        // 生日
        {
            name: 'birthDate',
            key: "birthDate",
            label: "PATIENT.BIRTHDATA",
            value: "",
            type: "datePicker",
            defaultValue: "",
            maxLength: 15,
            message: "PATIENT.BIRTHDATA",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ],
            modal: ['add', 'edit'],
        },
        // 电话
        {
            name: 'tel',
            key: "tel",
            label: "USERS.TEL",
            value: "",
            type: "input",
            options: [],
            message: "USERS.TEL",
            rules: [
                {
                    ...validatePhone,
                    message: intl.get('USERS.TEL_MSG_ERROR') // 手机号码格式错误
                }
            ],
            modal: ['add', 'edit'],
        },
    ];
}

// 正常 侧弯1 侧弯2 侧弯3
const colors = ['#9DD447', '#F8DA37', '#FCB42E', '#FB8346', '#5CB6FC'];

export function examsColumns() {
    return [
        {
            title: intl.get('PATIENT.NAME'),
            dataIndex: 'name',
            onFilter: (value, record) => record.name.startsWith(value),
            sorter: false,
            width: 120,
            align: 'center',
            excelCol: true,
            listCol: true,
            excelKey: 'name'
        },
        {
            title: intl.get('PATIENT.EXAM_DATE'),
            dataIndex: 'examTime',
            filterSearch: true,
            sorter: false,
            width: 150,
            align: 'center',
            excelCol: true,
            listCol: true,
            excelKey: 'examTime'
        },
        {
            title: intl.get('EXAM.QUICK_EXAM_COL_NAME'),
            dataIndex: 'quickData',
            width: 120,
            align: 'center',
            excelCol: true,
            listCol: false,
            excelKey: 'quickData',
        },
        {
            title: intl.get('EXAM.EXAM_DETAILS'),
            dataIndex: 'res',
            width: 120,
            align: 'center',
            excelCol: true,
            listCol: true,
            excelKey: 'res',
            render: (_, record) => {
                return <div style={{ color: record.resultId !== 5 ? colors[record.resultId] : colors[4] }}>{record.res}</div>
            }
        },
    ];
}
