import {combineReducers} from "redux";
import platform from "./platform"; 
import patients from "./patients"
import exams from "./exams";
import orgs from "./orgs";
import users from "./users"
import projects from "./projects";
import btCode from "./btCode";
import logs from "./logs"
import report from './report';
import actionWarehouses from './actionWarehouse';
import exePrescription from "./exePrescription"
// 使用combineReducers来组合所有reducer
const index = combineReducers({
  platform,
  patients,
  exams,
  orgs,
  users,
  projects,
  btCode,
  logs,
  report,
  actionWarehouses,
  exePrescription
})
export default index;
