import * as ActionTypes from '../ActionTypes';
import { projectSearch } from '../../pages/project/utils';
import { reduxSearchData } from "../../utils/dataUtils"

function exams(
    state = {
        projectsList: { list: [], total: 0 },
        projectsList_DV: { list: [], total: 0 },
        projectsSearch: reduxSearchData(projectSearch)
    },
    action) {
    switch (action.type) {
        case ActionTypes.SET_PROJECT_SEARCH:
            return {
                ...state,
                projectsSearch: { ...state.projectsSearch, ...action.data }
            };
        case ActionTypes.SET_PROJECTS_LIST:
            return {
                ...state,
                projectsList: { ...state.projectsList, ...action.data }
            };
            
        // v1.0.3-DV版本更新数据
        case ActionTypes.SET_PROJECTS_LIST_DV:
            return {
                ...state,
                projectsList_DV: { ...state.projectsList_DV, ...action.data }
            };
        default:
            return state;
    }
}

export default exams;
