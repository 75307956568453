import { Form, Select } from "antd";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";

// 检查记录接口
import { apiGetExamsList } from "../../../../actions/api.js";

/**
 * 对 受检单位数据 进行排序, 已检测过的在前, 且保持与显示的字段顺序一致
 *      PS: 该方法暂时没有复用, 先放在当前组件内(需要时再抽离)
 * @param schoolList 经过预处理的受检单位数据结构
 * @param examinedSchoolIdLists 已检测过的受检单位数据
 * @returns
 */
const sortSchoolList = (
  schoolList: API.TSchoolOptions,
  examinedSchoolIdLists: string[]
) => {
  // 定义默认结果集
  const sortedSchoolList: any[] = [];

  // 对 受检单位数据 判空
  if (schoolList && schoolList.length) {
    // 先遍历获取已检受检单位测数据并追加到 结果集头部
    examinedSchoolIdLists.forEach((id) => {
      schoolList.forEach((item) => {
        if (item.id === id) sortedSchoolList.push({ ...item });
      });
    });

    // 再将未检测的受检单位数据追加到 结果集尾部
    sortedSchoolList.push(
      ...schoolList.filter(({ id }) => !examinedSchoolIdLists.includes(id))
    );
  }

  // 返回排序完成的数据
  return sortedSchoolList;
};

const { Option } = Select;

/**
 * 项目关联受检单位下拉选中框组件 ------------------------------------
 * @param props
 * @returns
 */
const SchoolsLink: FC<API.TProjectSchoolsLink> = (props) => {
  // 获取表格当前行的数据 & 项目 id
  const {
    data,
    data: { id: projectId },
  } = props;

  // 获取 上下文表单实例
  const form = Form.useFormInstance();

  // 获取状态仓库的 orgs.shcoolList.list(受检单位列表) 数据 && 返回处理好的数据结构
  const storeSchoolList = useSelector<any, API.TSchoolOptions>((state: any) => {
    // 获取受检单位列表
    const { list } = state.orgs.shcoolList;

    // 判空
    if (!list.length) return [];
    // 对数据进行预处理 -- id 转为 string 类型
    return list.map((item: any) => ({ id: String(item.id), name: item.name }));
  });

  // 定义 强制刷新控制器 (也可以使用 reducer 自带的强制刷新api)
  const [forceUpdate, setForceUpdate] = useState(false);

  // 定义受检单位的数据
  const [schoolData, setSchoolData] = useState<{
    // 定义完成排序的受检单位数据列表
    sortedSchoolList: API.TSchoolOptions;
    // 定义项目已经检测过的受检单位的数据集
    examinedSchoolIdList: string[];
    // 初始化
  }>({ sortedSchoolList: [], examinedSchoolIdList: [] });

  // 调取检查记录接口数据
  useEffect(() => {
    // TODO: 接口只支持 size: 1000 这样获取所有数据
    apiGetExamsList({ size: 1000, proId: projectId }).then(
      (resp: API.TExaminResp) => {
        /**
         * 数据获取
         */
        const { rows } = resp;
        // 获取有检查记录的 受检单位id 列表
        const schoolIdList = rows.map(({ schoolId }) => String(schoolId));

        /**
         * 处理并更新受检单位数据 -- 需求是检测过的受检单位需要排在前面
         * 1. 获取项目关联的所有受检单位数据
         * 2. 获取已经检测过的受检单位数据
         * 3. 进行去重处理
         */
        // 进行去重处理 -- RD 去重缩写: remove duplication
        const RDSchoolIdList = [...new Set(schoolIdList)];
        // 对受检单位数据列表进行排序
        const sortedSchoolList = sortSchoolList(
          [...storeSchoolList],
          RDSchoolIdList
        );
        // 更新数据
        setSchoolData({
          sortedSchoolList,
          examinedSchoolIdList: RDSchoolIdList,
        });

        /**
         * 回显字段
         */
        // 筛选当前项目中没有检测过的受检单位
        const noExaminedSchoolIdList = data.schools.filter(
          (item) => !RDSchoolIdList.includes(item)
        );
        // 将检测过的受检单位数据放置在开头, 并再次去重, 完成排序
        form.setFieldValue("schools", [
          ...new Set([...RDSchoolIdList, ...noExaminedSchoolIdList]),
        ]);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.schools, projectId]);

  /**
   * select表单清除事件
   * 进行中项目的受检单位且受检单位有检测记录了, 不能被清除, 强制重新将该受检单位渲染回表单
   */
  const onClear = () => setForceUpdate(!forceUpdate); // 状态数据变化, 强行 render

  // 监听强制刷新器 -- 变化时, 更新表单 -- 使用 useLayoutEffect 可以避免渲染闪动
  useLayoutEffect(() => {
    // 回显 进行中项目, 且有检测记录 的受检单位内容
    form.setFieldValue("schools", schoolData.examinedSchoolIdList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  // 渲染
  return (
    <Form.Item name="schools" label={intl.get("PROJECT.SCHOOLS")}>
      <Select
        className="disable-school-tag"
        mode="multiple"
        allowClear
        showArrow
        onClear={onClear}
        // data.status 为 2 时, 表示项目已完成, 此时所有选中受检单位都不能不被修改
        disabled={data.status === 2}
      >
        {
          // 根据受检单位数据列表渲染 options
          schoolData.sortedSchoolList.length &&
            schoolData.sortedSchoolList.map(({ id, name }) => {
              // PS: 进行中的项目 包含的受检单位 有两种状态
              //      1. 已经做了检测 -- 此时该受检单位不能从当前项目中删除
              //      2. 还没有做检测 -- 可以从当前项目中删除

              // 定义禁用控制开关 -- 禁止删除项目状态进行中的受检单位
              const disabled =
                data.status === 1 && // 当前项目状态是否是在进行中
                data.schools?.includes(id) && // 当前项目是否包含该受检单位
                schoolData.examinedSchoolIdList.includes(id); // 该受检单位是否是已经检测过了

              // 渲染下拉选项
              return (
                <Option value={id} key={id} disabled={disabled}>
                  {name}
                </Option>
              );
            })
        }
      </Select>
    </Form.Item>
  );
};

export default SchoolsLink;
