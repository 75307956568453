import request from "@/utils/request";

/**
 * 查询项目
 * @param {*} params 
 * @returns 
 */
export const getProjectListRequest = (params?: any) => {
    return request.get<any, API.TAxiosResponseData<API.TGetProjectListResponseData>>('/api/v1/project/list', { params })
}

/**
 * 筛查进度
 * @param {*} params 
 * @returns 
 */
export const getExamineProgressRequest = (params: { startDate: string;  endDate: string;schoolId: string; }) => {
    return request.get<any, any>('/api/v1/stat/progress', { params })
}

/**
 * 性别图表
 * @param {*} params 
 * @returns 
 */
export const getExamineGenderRequest = (params: { startDate: string;  endDate: string; schoolId: string; }) => {
    return request.get<any, any>('/api/v1/stat/gender', { params })
}

/**
 * 比例统计图表
 * @param {*} params 
 * @returns 
 */
export const getExamineRateRequest = (params: {startDate: string;  endDate: string;schoolId: string; }) => {
    return request.get<any, any>('/api/v1/stat/rate', { params })
}

/**
 * 年龄图表
 * @param {*} params 
 * @returns 
 */
export const getExamineAgeRequest = (params: {startDate: string;  endDate: string; schoolId: string; ageSeg: string }) => {
    return request.get<any, any>('/api/v1/stat/age', { params })
}
