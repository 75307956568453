import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { queryCurrentLanguage, handleSwitchLanguage } from '../../utils/languageTools.js'
import intl from 'react-intl-universal'; // 三方库初始化语言api
import style from './index.module.scss';

// 私有化样式
const { languageSwitch } = style;
class Language extends Component {
    state = {
        langs: [
            {
                label: intl.get('LANGUAGE.SIMPLIFIED_CHINESE'),
                key: 'zh',
            },
            {
                label: intl.get('LANGUAGE.TRADITIONAL_CHINESE'),
                key: 'hk',
            },
            // {
            //     label: intl.get('LANGUAGE.ENGLISH'),
            //     key: 'en',
            // },
        ]
    }
    render() {
        const name = this.props.name ? this.props.name : '';
        return (
            <Dropdown
                menu={{ items: this.state.langs, onClick: handleSwitchLanguage }}
                trigger={['click']}
                placement='bottom'
            >
                <button className={languageSwitch + '' + name}>
                    <Space>
                        {queryCurrentLanguage(this.state.langs)}
                        <DownOutlined />
                    </Space>
                </button>
            </Dropdown>
        );
    }
}
export default Language;