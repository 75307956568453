import {
  SET_ORGS_LIST,
  SET_ORGS_SEARCH,
  SET_ORGS_LIST_DV,
  SET_SCHOOL_LIST,
} from "../ActionTypes";
import {
  apiGetOrgsList,
  apiAddOrg,
  apiEditOrg,
  apiDelOrg,
} from "../../actions/api";
import {
  apiGetSchoolsList,
  apiAddSchool,
  apiEditSchool,
  apiDelSchool,
} from "../../actions/api";
import store from "../../store";
import { message } from "antd";
import intl from "react-intl-universal";
import { delSearchNull } from "../../utils/dataUtils";
import { takeLatest, cancelled, call, put } from "redux-saga/effects";
import { isHadLogin } from "./platform.js";
import { getCurrentUser } from "../../redux/selecters/platform.js";
export const setOrgsList = (data) => {
  return {
    type: SET_ORGS_LIST,
    data,
  };
};

// orgsList_DV
export const setOrgsList_DV = (data) => {
  return {
    type: SET_ORGS_LIST_DV,
    data,
  };
};

/**
 * 受检单位列表 action
 * @param {*} data
 * @returns
 */
export const setSchoolList = (data) => {
  return {
    type: SET_SCHOOL_LIST,
    data,
  };
};

export const setOrgsSearch = (data) => {
  return {
    type: SET_ORGS_SEARCH,
    data,
  };
};
/**机构*************************************************************************************************** */
export function* watchSwitchOrgsSearch() {
  yield takeLatest("SET_ORGS_SEARCH", getOrgsList);
}

export function* getOrgsList() {
  let search = delSearchNull(store.getState().orgs.orgsSearch);
  let currentUser = getCurrentUser(store.getState());
  // isHadLogin() 用户名
  if (isHadLogin()) {
    try {
      const data = yield call(
        currentUser.role === 0 ? apiGetOrgsList : apiGetSchoolsList,
        search
      );
      const Elist = {
        list: data.rows,
        total: data.total,
      };
      yield put(
        currentUser.role === 0 ? setOrgsList(Elist) : setSchoolList(Elist)
      );
    } catch {
      cancelled();
    } finally {
    }
  }
}

/**
 * 获取 异步接口受检单位列表
 */
export function getSchoolListResp() {
  return async (dispatch) => {
    // 未登录 -- 结束
    if (!isHadLogin()) return;

    // 登录 -- 调取接口
    // TODO: 接口只支持 size: 1000 这样获取所有数据
    const { rows, total } = await apiGetSchoolsList({ size: 1000 });

    // 派发 -- 存储
    dispatch(setSchoolList({ list: rows, total }));
  };
}

/**
 * 获取机构数据
 * @returns
 */
export function getOrgsList_DV() {
  return async (dispatch) => {
    try {
      // 调取接口
      // TODO: 接口只支持 size: 1000 这样获取所有数据
      const { rows, total } = await apiGetOrgsList({ size: 1000 });
      // 派发存储仓库动作
      dispatch(setOrgsList_DV({ list: rows, total }));
    } catch (error) {}
  };
}

export function getAllOrgsList() {
  let search = delSearchNull(store.getState().orgs.orgsSearch);
  return function (dispatch) {
    apiGetOrgsList(search)
      .then((data) => {
        const Elist = {
          list: data.rows,
          total: data.total,
        };
        dispatch(setOrgsList(Elist));
      })
      .catch(() => {});
  };
}

export function addOrg(data, updateModal) {
  return function (dispatch) {
    apiAddOrg(data)
      .then(() => {
        message.success(intl.get("ACTION.ADD_SUCCESS"));
        dispatch(getAllOrgsList());
        updateModal.current.handleCancel();
      })
      .catch((error) => {});
  };
}

export function editOrg(data, updateModal) {
  return function (dispatch) {
    apiEditOrg(data)
      .then(() => {
        message.success(intl.get("ACTION.EDIT_SUCCESS"));
        dispatch(getAllOrgsList());
        updateModal.current.handleCancel();
      })
      .catch(() => {});
  };
}

export function delOrg(data) {
  return function (dispatch) {
    apiDelOrg(data)
      .then(() => {
        message.success(intl.get("ACTION.DEL_SUCCESS"));
        dispatch(getAllOrgsList());
      })
      .catch(() => {});
  };
}

/**受检单位*************************************************************************************************** */
export function getAllSchoolList() {
  let search = delSearchNull(store.getState().orgs.orgsSearch);
  return function (dispatch) {
    apiGetSchoolsList(search)
      .then((data) => {
        const Elist = {
          list: data.rows,
          total: data.total,
        };
        dispatch(setSchoolList(Elist));
      })
      .catch(() => {});
  };
}

export function addSchool(data, updateModal) {
  return function (dispatch) {
    apiAddSchool(data)
      .then(() => {
        message.success(intl.get("ACTION.ADD_SUCCESS"));
        dispatch(getAllSchoolList());
        updateModal.current.handleCancel();
      })
      .catch(() => {});
  };
}

export function editSchool(data, updateModal) {
  return function (dispatch) {
    apiEditSchool(data)
      .then(() => {
        message.success(intl.get("ACTION.EDIT_SUCCESS"));
        dispatch(getAllSchoolList());
        updateModal.current.handleCancel();
      })
      .catch(() => {});
  };
}

export function delSchool(data) {
  return function (dispatch) {
    apiDelSchool(data)
      .then(() => {
        message.success(intl.get("ACTION.DEL_SUCCESS"));
        dispatch(getAllSchoolList());
      })
      .catch((error) => {
        console.warn(error.message);
      });
  };
}
