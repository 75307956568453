// 导入
import { useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { Button, Form, Input } from "antd";
import { withRouter } from "react-router-dom";
import { validatePhone } from "../../utils/regUtils.js";
import CountDown from "../../components/login/countDown.js";
import scolionetLogo from "../../static/images/scolionetLogo.png";
import { apiGetExamsList } from "../../actions/api.js";
import { MobileOutlined } from "@ant-design/icons";
import JoinItem from "./components/JoinItem/index.jsx";
import { formPwByType } from "../../utils/dataUtils.js";
import Language from "../../components/language/index.js";
import style from "./index.module.scss";
import "./index.scss";

const ReportScreen = lazy(() => import("./components/ReportScreen/index.jsx"));
// 获取私有样式类名
const { container, updateUser, languageBox, logo, queryButton } = style;
const { phoneBox, phoneInput, codeBox, codeInput, queryBox, footer } = style;
// ExamsByPhone 组件
function ExamsByPhone() {
  const [userPhone, setUserPhone] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);

  // 0-examsByPhone, 1-JoinItem, 2-ReportScreen
  const [display, setDisplay] = useState(0);
  const [patientId, setPatientId] = useState(0);
  const [formUserInfo] = Form.useForm();
  // 定义 loadings 状态和 setLoadings 函数
  const [loadings, setLoadings] = useState(false);

  /**
   * 加载处理函数
   * @returns
   */
  const handleLoadings = () => setLoadings((prevLoadings) => !prevLoadings);

  /**
   * 表单正确提交处理函数
   * @param {*} forms 表单数据对象
   */
  const onFinish = async (forms: { code: any; }) => {
    // 开启加载转圈动画
    handleLoadings();
    // 调用查询检查记录接口 -- 传递电话和验证码
    forms.code = formPwByType(forms.code, "+");
    try {
      const { rows } = await apiGetExamsList(forms);
      localStorage.setItem("queryReport", JSON.stringify(rows));
      // 接口调用完毕 --> 关闭加载转圈动画
      handleLoadings();
      // 路由跳转 -- 跳转到 加入的项目 页面
      setDisplay(() => 1);
    }
    catch (error) {
      handleLoadings();
    }
  };

  /**
   * 表单错误提交处理函数
   * @param {*} errorInfo 错误信息对象
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("表单校验不通过", errorInfo);
  };

  /**
   * 0-examsByPhone, 1-JoinItem, 2-ReportScreen
   * @param {*} page 0 / 1 / 2
   * @param {*} patientId 受检人id
   */
  const jumpCallback = (page: any, patientId?: any) => {
    if (patientId) setPatientId(() => patientId);
    setDisplay(() => page);
  };

  // 模板
  return (
    <div className="examsByPhonePage">
      <div
        className={container}
        style={{ display: display === 0 ? "flex" : "none" }}
      >
        <div className={languageBox}>
          <Language />
        </div>
        {/* logo图 */}
        <div className={logo}>
          <img src={scolionetLogo} alt="" />
        </div>
        {/* 表单 */}
        <Form
          form={formUserInfo}
          name={updateUser}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* 电话号码输入框 */}
          <Form.Item
            className={phoneBox}
            name="tel"
            rules={[
              {
                required: true,
                message: intl.get("LOGIN.PHONE_NULL"),
              },
              {
                ...validatePhone,
                message: intl.get("USERS.TEL_MSG_ERROR"),
              },
            ]}
          >
            <Input
              className={phoneInput}
              placeholder={intl.get("QUICK_QUERY.PHONE")}
              prefix={<MobileOutlined />}
              onChange={(e) => {
                setUserPhone(e.target.value);
              }}
              disabled={phoneDisabled}
            />
          </Form.Item>
          {/* 验证码输入框 */}
          <Form.Item
            className={phoneBox + " " + codeBox}
            name="code"
            rules={[
              {
                required: true,
                message: intl.get("LOGIN.CODE_NULL"),
              },
            ]}
          >
            <Input
              className={phoneInput + " " + codeInput}
              placeholder={intl.get("QUICK_QUERY.CODE")}
              suffix={
                <CountDown
                  userPhone={userPhone}
                  setPhoneDis={(data: boolean | ((prevState: boolean) => boolean)) => setPhoneDisabled(data)}
                />
              }
            />
          </Form.Item>
          {/* 查询按钮 */}
          <Form.Item
            className={phoneBox + " " + queryBox}
            wrapperCol={{
              span: 24,
            }}
          >
            <div>
              <Button
                className={queryButton}
                type="primary"
                htmlType="submit"
                loading={loadings}
              >
                {intl.get("ACTION.SEARCH")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div style={{ display: display === 1 ? "block" : "none" }}>
        <JoinItem
          callback={(page: any, patientId: any) => {
            jumpCallback(page, patientId);
          }}
        />
      </div>
      <div style={{ display: display === 2 ? "block" : "none" }}>
        <Suspense>
          <ReportScreen
            callback={(page: any) => jumpCallback(page)}
            patientId={patientId}
          />
        </Suspense>
      </div>
      <div className={footer}>
        <p>{intl.get("INDEX.SCOLIOSCAN")}</p>
      </div>
    </div>
  );
}
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(withRouter(ExamsByPhone));
