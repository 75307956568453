// 状态管理
import { connect, useDispatch } from 'react-redux';
import { getCurrentUser } from "@/redux/selecters/platform.js"

// 图片
import scolionetLogo from "@/static/images/scolionetLogo.png";
import { UserOutlined, DownOutlined } from '@ant-design/icons';

// 组件
// import Language from '@/components/language/index.js';
import { logoutAction } from "@/actions/login.js"
import { Dropdown, Button } from 'antd';

// 路由
import { withRouter, Link, useLocation, useHistory } from 'react-router-dom';

// 多语言
import intl from 'react-intl-universal';

// 样式
import style from './index.module.less';

const { header } = style;


/**
 * 系统头部组件 --------------------------------------------
 * @param props 
 * @returns 
 */
function Header(props: { currentUser: any; }) {

    // 获取当前页面 url 地址 -- 用于 大屏页面返回时的定位url
    const { pathname } = useLocation();

    // 退出登录
    const history = useHistory()
    const dispatch = useDispatch();
    const logout = () => dispatch(logoutAction(history));

    // 获取当前用户信息
    const { currentUser: { username, roleId } } = props;
    // 定义 Dropdown的prop: items 数据集
    const items = [
        {
            label: (<div className='logout'
                style={{ textAlign: "center" }}
                onClick={() => logout()} >
                {intl.get('LOGIN.LOGOUT')}</div>),
            key: '0',
        },
    ];


    // 渲染
    return (
        <div className={header}>
            <div className='header_logo' >
                <img src={scolionetLogo} alt='header_logo' />
            </div>
            <div className='header_right'>
                {   // 数据大屏模块跳转按钮
                    // 角色为生产时, 不需要跳转按钮
                    roleId !== 3 && <Link to={{
                        pathname: '/dataLargeScreen',
                        search: pathname, // 携带当前页面 url
                    }}><Button className='dataLargeScreen-button'>
                            {intl.get("DATALARGESCREEN.MODULE_NAME")} {/* 数据大屏 */}
                        </Button>
                    </Link>
                }


                {/* 临时按钮 */}
                {process.env.NODE_ENV === "development" && <Link to={{
                    pathname: '/webview/haiying-report-search',
                    search: 'id=977869232668676&n=张三1&g=m&a=10', // 携带参数
                }}>海鹰</Link>}

                {process.env.NODE_ENV === "development" && <Link to={{
                    pathname: '/webview/add-patient-form',
                    search: 'oi=1808318451535273985&si=1808322154103267330&pn=测试内容', // 携带参数
                }} style={{ marginLeft: '20px' }}>扫码添加受检人</Link>}

                {/* 语言切换 */}
                {/* <Language /> */}

                {/* 用户信息下拉菜单 */}
                <Dropdown
                    className='header_user'
                    placement="bottom"
                    menu={{ items }}
                    trigger={['click']} >
                    <span>
                        {/* 下拉框内容 */}
                        <UserOutlined /> &nbsp;{username || ''} &nbsp;<DownOutlined />
                    </span>
                </Dropdown>
            </div>
        </div >
    )
}

const mapStateToProps = (state: any) => ({ currentUser: getCurrentUser(state) });

export default connect(mapStateToProps, {})(withRouter(Header));