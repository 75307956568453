import intl from 'react-intl-universal';

export const orgSearch = []

export function orgsColumns() {
  return [
    {
      title: intl.get('ORGS.ORG_NAME'), // 名称
      dataIndex: 'orgName',
      // sorter: true,
      width: 150,
      align: 'center'
    },
    {
      title: intl.get('ORGS.ORGS_CLINICID'), // 诊所编号
      dataIndex: 'clinicId',
      // sorter: true,
      width: 100,
      align: 'center'
    },
    {
      title: intl.get('ORGS.ORGS_TIME'), // 创建时间
      dataIndex: 'createtime',
      // sorter: true,
      width: 120,
      align: 'center',
      render: (_: any, record: { createtime: string | any[]; }) => <div>{record.createtime?.slice(0, 10)} </div>
    },

  ];
}

export function schoolColumns() {
  return [
    {
      title: intl.get('ORGS.ORG_NAME'), // 名称
      dataIndex: 'name',
      // sorter: true,
      width: 100,
      align: 'center',
    },
    {
      title: intl.get('ORGS.OWNING_REGION'), // 所属区域
      // 省/市/区 -- province/city/district
      dataIndex: 'PCD',
      width: 200,
      align: 'center',
    },
  ];
}

export function orgForm() {
  return [
    {
      name: 'orgName',
      label: "ORGS.ORG_NAME",
      value: "",
      type: "input",
      defaultValue: "",
      maxLength: 30,
      message: "ORGS.ORG_NAME_MSG",
      rules: [
        {
          required: true,
          message: intl.get("COMMON.IS_REQUIRED"),
        },
      ]
    }, {
      name: 'clinicId',
      label: "ORGS.ORGS_CLINICID",
      value: "",
      type: "input",
      defaultValue: "",
      maxLength: 30,
      message: "ORGS.ORGS_CLINICID",
      rules: []
    },
  ]
}

export function schoolForm() {
  return [
    {
      name: 'name',
      label: "ORGS.ORG_NAME",
      value: "",
      type: "input",
      defaultValue: "",
      maxLength: 30,
      message: "ORGS.ORG_NAME_MSG",
      rules: [
        {
          required: true,
          message: intl.get("COMMON.IS_REQUIRED"),
        },
      ]
    },
    {
      type: "PCDLinkSelect",
      label: "ORGS.OWNING_REGION", // 所属区域
      component: undefined
    }
  ]
}