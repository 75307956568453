import {
    apigetExePatList,
    apiaddExePrescription,
    apigetExePrescriptionList,
    apiGetPatInfoById,
    apiEditExePrescription,
    apiGetExePrescriptionInfoById,
    apiGetSysPrescriptionList,
    apiAddSystemExePrescription,
    apiUploadCover,
    apiGetAllSysPrescriptionList,
    apiGetSysPrescriptionById,
    apiEditSystemExePrescription
} from "../../actions/api"
import intl from 'react-intl-universal';
import { message } from 'antd'
import {
    SET_CHECKED_EXE_LIST,
    SET_CASE_INFO,
    SET_EXE_PRESCRIPTION_LIST,
    SET_EXE_PRESCRIPTION_SEARCH,
    SET_ESELECTED_PAT_TO_EXE,
    SET_ESELECTED_UPDATE_EXE_ID,
    SET_SYSTEM_EXE_PRESCRIPTION_LIST,
    SET_SYSTEM_EXE_PRESCRIPTION_SEARCH
} from "../ActionTypes"
import { formExamList } from "./exams"
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';
import { isHadLogin } from "./platform.js";
import { delSearchNull } from "../../utils/dataUtils";
import { positionOptions } from "../../pages/actionWarehouse/actionWarehouseInfo"
export const setcheckedExeList = (data) => {
    return {
        type: SET_CHECKED_EXE_LIST,
        data
    }
}
export const setCaseInfo = (data) => {
    return {
        type: SET_CASE_INFO,
        data
    }
}
export const setExePrescriptionList = (data) => {
    return {
        type: SET_EXE_PRESCRIPTION_LIST,
        data
    }
}
export const setExePrescriptionSearch = (data) => {
    return {
        type: SET_EXE_PRESCRIPTION_SEARCH,
        data
    }
}

export const setSelectedPatToExe = (data) => {
    return {
        type: SET_ESELECTED_PAT_TO_EXE,
        data
    }
}
export const setSelectedUpadteExeId = (data) => {
    return {
        type: SET_ESELECTED_UPDATE_EXE_ID,
        data
    }
}

/**
 * 用于运动库列表的redux存储初始化
 */
export function setPriPosList() {
    let json = {}
    positionOptions.forEach((pos) => {
        json[pos['value']] = []
    })
    return json
}

//**处方模块************************************************************************************* */
export function* watchSwitchExePrescriptionSearch() {
    yield takeLatest('SET_EXE_PRESCRIPTION_SEARCH', getExePrescriptionList);
}


export function* getExePrescriptionList() {
    const search = delSearchNull(store.getState().exePrescription.exePrescriptionSearch);
    if (isHadLogin()) {
        try {
            const data = yield call(apigetExePrescriptionList, search);
            const Elist = {
                list: data.rows,
                total: data.total
            };
            yield put(setExePrescriptionList(Elist))
        } catch (error) {
            cancelled()
        } finally {

        }
    }
}


export function getExePrescriptionListBySearch() {
    const search = delSearchNull(store.getState().exePrescription.exePrescriptionSearch)
    return function (dispatch) {
        apigetExePrescriptionList(search)
            .then(data => {
                const Elist = {
                    list: data.rows,
                    total: data.total
                };
                dispatch(setExePrescriptionList(Elist))
            }).catch(() => { })
    }
}

export function getExePrescriptionListByParams(search) {
    return apigetExePrescriptionList(search)
        .then(data => {
            const Elist = {
                list: data.rows,
                total: data.total
            };
            return Elist
        }).catch(() => { })
}


/**
 * 获取患者列表
 * @param {*} search 
 * @returns 
 */

export function getExePatList(search) {
    return apigetExePatList(search)
        .then(data => {
            const Elist = {
                list: formExamList(data.rows),
                total: data.total
            };
            return Elist
        }).catch(() => {
            // message.success(intl.get("DICTIONARY.REQUEST_FAILED"))
        })

}

/**
 * 新增处方
 * @param {*} data 
 * @param {*} updateModal 
 * @returns 
 */
export function addExePrescription(data, updateModal) {
    return function (dispatch) {
        apiaddExePrescription(data)
            .then(() => {
                message.success(intl.get("ACTION.ADD_SUCCESS"))
                dispatch(getExePrescriptionListBySearch())
                updateModal.current.handleCancel();
            }).catch(() => { })
    }
}
/**
 * 根据患者id获取患者信息
 * @param {} data 
 * @returns 
 */
export function getPatInfoById(data) {
    return apiGetPatInfoById(data)
        .then((res) => {
            return res.rows[0]
        }).catch(() => {
            return {}
        })
}
/**
 * 开具处方
 * @param {} data 
 * @returns 
 */
export function editExePrescription(pageType, data, history) {
    return apiEditExePrescription(data)
        .then(() => {
            message.success(pageType === 'update' ? intl.get('ACTION.EDIT_SUCCESS') : intl.get('ACTION.ISSUE_SUCCESS'))
            setTimeout(() => {
                history.push("/mainPage/ExePrescription?type=issuePreScription")
            }, 1000)
        }).catch(() => { })
}

/**
 * 根据id获取处方详情
 * @param {} data 
 * @returns 
 */
export function getExePrescriptionInfoById(data) {
    return apiGetExePrescriptionInfoById(data)
        .then((res) => {
            return res.rows[0]
        }).catch(() => { })
}
//**系统处方模块************************************************************************************* */


export const setSystemExePrescriptionList = (data) => {
    return {
        type: SET_SYSTEM_EXE_PRESCRIPTION_LIST,
        data
    }
}
export const setSystemExePrescriptionSearch = (data) => {
    return {
        type: SET_SYSTEM_EXE_PRESCRIPTION_SEARCH,
        data
    }
}

export function* watchSwitchSystemExePrescriptionSearch() {
    yield takeLatest('SET_SYSTEM_EXE_PRESCRIPTION_SEARCH', getSystemExePrescriptionList);
}


export function* getSystemExePrescriptionList() {
    const search = delSearchNull(store.getState().exePrescription.systemExePrescriptionSearch);
    if (isHadLogin()) {
        try {
            const data = yield call(apiGetSysPrescriptionList, search);
            const Elist = {
                list: data.rows,
                total: data.total
            };
            yield put(setSystemExePrescriptionList(Elist))
        } catch (error) {
            cancelled()
        } finally {

        }
    }
}


export function getSystemExePrescriptionListBySearch() {
    const search = delSearchNull(store.getState().exePrescription.systemExePrescriptionSearch)
    return function (dispatch) {
        apiGetSysPrescriptionList(search)
            .then(data => {
                const Elist = {
                    list: data.rows,
                    total: data.total
                };
                dispatch(setSystemExePrescriptionList(Elist))
            }).catch(() => { })
    }
}


/**
 * 新增系统处方
 * @param {} pageType 
 * @param {*} data 
 * @param {*} history 
 * @returns 
 */
export function addSystemExePrescription(data, history) {
    return apiAddSystemExePrescription(data)
        .then(() => {
            message.success("开具成功")
            setTimeout(() => {
                history.push("/mainPage/ExePrescription?type=systemPreScription")
            }, 1000)
        }).catch(() => { })
}

/**
 * 编辑系统处方
 * @param {} pageType 
 * @param {*} data 
 * @param {*} history 
 * @returns 
 */
export function editSystemExePrescription(data, history) {
    return apiEditSystemExePrescription(data)
        .then(() => {
            message.success(intl.get('ACTION.EDIT_SUCCESS'))
            setTimeout(() => {
                history.push("/mainPage/ExePrescription?type=systemPreScription")
            }, 1000)
        }).catch(() => { })
}


/**
 * 上传封面
 * @param {封面文件} data 
 * @returns 
 */
export function uploadCover(data) {
    return apiUploadCover(data)
        .then(res => {
            message.success(intl.get("ACTION.UPLOAD_SUCCESS"))
            return res.rows
        }).catch(() => {
            message.success(intl.get("ACTION.UOLOAD_FAILED"))
            return 0
        })

}
/**
 * 获取所有系统处方
 * @returns 
 */
export function getAllSysPrescriptionList() {
    return apiGetAllSysPrescriptionList()
        .then(data => {
            return data.rows
        }).catch(() => { })
}

/**
 * 根据id获取系统处方的详情
 * @returns 
 */
export function getSysPrescriptionById(id) {
    return apiGetSysPrescriptionById(id)
        .then(data => {
            return data.rows[0]
        }).catch(() => { })
}

/**
 * 用于根据后台返回的数据格式化后渲染页面上的视频和方案列表数据
 * @param {*} resList 
 * @returns 
 */
export function formResListToUpdateCheckExeList(resList) {
    return function (dispatch) {
        const videoJson = {};
        const tableList = []
        resList.forEach((item) => {
            const parts = item.partId.split(",");
            parts.forEach((part) => {
                if (!videoJson[part]) {
                    videoJson[part] = [];
                }
                videoJson[part].push(item);
            });
            tableList.push({ id: item.id, name: item.name, groupsNum: item.groupCount })
        });
        dispatch(setcheckedExeList({ JsonList: tableList.length > 0 ? videoJson : setPriPosList(), tableList: tableList }))
    }
}