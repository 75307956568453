// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qdxy7lOipDJCX5w8mUMu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  line-height: 80px;
  background-color: #fff;
  border-bottom: 5px solid #f0f2f5;
}
.qdxy7lOipDJCX5w8mUMu > div {
  margin: 0 20px;
}
.qdxy7lOipDJCX5w8mUMu .header_logo img {
  height: 60px;
  margin-bottom: 10px;
}
.qdxy7lOipDJCX5w8mUMu .header_right {
  display: flex;
  justify-content: flex-start;
}
.qdxy7lOipDJCX5w8mUMu .header_right img {
  margin-top: -5px;
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.qdxy7lOipDJCX5w8mUMu .header_user {
  height: 35px;
  line-height: 35px;
  margin-top: 20px;
  margin-right: 30px;
  padding: 0 12px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}
.qdxy7lOipDJCX5w8mUMu .dataLargeScreen-button {
  background-color: #98d5b9;
  border: 1px solid #98d5b9;
  color: #fff;
  margin-right: 6px;
}
.qdxy7lOipDJCX5w8mUMu .dataLargeScreen-button:hover {
  color: white;
  background-color: #83ebba;
  border: 1px solid #83ebba;
}
.qdxy7lOipDJCX5w8mUMu .logout {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/layout/components/Header/index.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,gCAAA;AACF;AARA;EAUI,cAAA;AACJ;AAXA;EAgBQ,YAAA;EACA,mBAAA;AAFR;AAfA;EAsBM,aAAA;EACA,2BAAA;AAJN;AAnBA;EA0BQ,gBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAJR;AAzBA;EAkCM,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AANN;AAnCA;EA6CM,yBAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;AAPN;AASM;EACE,YAAA;EACA,yBAAA;EACA,yBAAA;AAPR;AA9CA;EA0DM,kBAAA;AATN","sourcesContent":[".header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  height: 80px;\n  line-height: 80px;\n  background-color: #fff;\n  border-bottom: 5px solid #f0f2f5;\n\n  >div {\n    margin: 0 20px;\n  }\n\n  :global {\n    .header_logo {\n      img {\n        height: 60px;\n        margin-bottom: 10px;\n      }\n    }\n\n    .header_right {\n      display: flex;\n      justify-content: flex-start;\n\n      img {\n        margin-top: -5px;\n        width: 20px;\n        height: 20px;\n        margin-right: 20px;\n      }\n    }\n\n    .header_user {\n      height: 35px;\n      line-height: 35px;\n      margin-top: 20px;\n      margin-right: 30px;\n      padding: 0 12px;\n      border-radius: 2px;\n      text-align: center;\n      cursor: pointer;\n    }\n\n    .dataLargeScreen-button {\n      background-color: #98d5b9;\n      border: 1px solid #98d5b9;\n      color: #fff;\n      margin-right: 6px;\n\n      &:hover {\n        color: white;\n        background-color: #83ebba;\n        border: 1px solid #83ebba;\n      }\n    }\n\n    .logout {\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `qdxy7lOipDJCX5w8mUMu`
};
export default ___CSS_LOADER_EXPORT___;
