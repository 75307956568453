
import { SET_ACTION_WAREHOUSES_LIST, SET_ACTION_WAREHOUSES_SEARCH } from '../ActionTypes';
import { apietActionWarehouseList, apiUploadVideo,apiAddActionWarehouse,apiEditActionWarehouse,apiDelActionWarehouse } from "../../actions/api";
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';
import intl from 'react-intl-universal';
import { message } from 'antd'
import { delSearchNull } from "../../utils/dataUtils";
// import { getExameResultStr } from "../../redux/selecters/platform";
import { isHadLogin } from "./platform.js";

export const setactionWarehousesList = (data) => {
    return {
        type: SET_ACTION_WAREHOUSES_LIST,
        data
    }
}
export const setactionWarehousesSearch = (data) => {
    return {
        type: SET_ACTION_WAREHOUSES_SEARCH,
        data
    }
}

/*************************************************************************************** */
export function* watchSwitchactionWarehousesSearch() {
    yield takeLatest('SET_ACTION_WAREHOUSES_SEARCH', getactionWarehousesList);
}


export function* getactionWarehousesList() {
    const search = delSearchNull(store.getState().actionWarehouses.actionWarehousesSearch);
    if (isHadLogin()) {
        try {
            const data = yield call(apietActionWarehouseList, search);
            const Elist = {
                list: data.rows,
                total: data.total
            };
            yield put(setactionWarehousesList(Elist))
        } catch (error) {
            cancelled()
        } finally {

        }
    }
}


export function getactionWarehousesListBySearch() {
    const search = delSearchNull(store.getState().actionWarehouses.actionWarehousesSearch)
    return function (dispatch) {
        apietActionWarehouseList(search)
            .then(data => {
                const Elist = {
                    list: data.rows,
                    total: data.total
                };
                dispatch(setactionWarehousesList(Elist))
            }).catch(() => { })
    }
}

export function getActionWarehousesByParams(search) {
    return apietActionWarehouseList(search)
        .then(data => {
            const Elist = {
                list: data.rows,
                total: data.total
            };
            return Elist
        }).catch(() => { })
}
/**
 * 上传视频
 * @param {视频文件} data 
 * @returns 
 */
export function uploadVideo(data) {
    return apiUploadVideo(data)
        .then(res => {
            message.success(intl.get("ACTION.UPLOAD_VIDEO_SUCCESS"))
            return res.rows
        }).catch(() => { 
            message.success(intl.get("ACTION.UPLOAD_VIDEO_FAILED"))
            return 'failed'
        })

}
/**
 * 新增动作
 * @param {动作参数} data 
 * @param {*弹窗} updateModal 
 * @returns 
 */
export function addActionWarehouse(data, updateModal) {
    if(data.videoId===""){
        message.warn(intl.get("ACTION.VIDEO_NULL"))
        return
    }
    return function (dispatch) {
        apiAddActionWarehouse(data)
            .then(() => {
                message.success(intl.get("ACTION.ADD_SUCCESS"))
                dispatch(getactionWarehousesListBySearch())
                updateModal.current.handleCancel();
            }).catch(() => { })
    }
}

export function editActionWarehouse(data, updateModal) {
    return function (dispatch) {
        apiEditActionWarehouse(data)
            .then(() => {
                message.success(intl.get("ACTION.EDIT_SUCCESS"))
                dispatch(getactionWarehousesListBySearch())
                updateModal.current.handleCancel()
            }).catch(() => { })
    }
}



export function delActionById(motionId) {
    return function (dispatch) {
        apiDelActionWarehouse(motionId)
            .then(() => {
                message.success(intl.get("ACTION.DEL_SUCCESS"))
                dispatch(getactionWarehousesListBySearch())
            }).catch(() => { })
    }
}