import axios, { AxiosResponse } from 'axios';
import { message } from 'antd';
import intl from 'react-intl-universal';

// 弹窗
const { error: errorMessage, loading } = message;

// 控制登录过期后的接口返回
let flag = true;

/**
 * 创建axios实例 && 进行默认配置
 */
const axiosInstance = axios.create({
    timeout: 60000, // 15s过期
    baseURL: '',
    // http://39.108.219.134:8099/
});

/**
 * 请求拦截
 */
axiosInstance.interceptors.request.use(
    /**
     * 请求成功(请求创建成功)
     * @param config 
     * @returns 
     */
    (config: any) => {
        if (!flag) return // 登录过期后, 拦截接口请求
        return config;
    },
    /**
     * 请求发起失败
     * @param error 
     * @returns 
     */
    (error) => {
        return Promise.reject(error);
    }
);

// 流数据的接口, 另作处理, 加入 blobList
const blobList = ["/api/v1/exams/download/ultrasonic", "/api/v1/exams/exportExcel", "/api/subions/exams201/info"]

/**
 * 响应拦截
 */
axiosInstance.interceptors.response.use(
    /**
     * 响应成功
     * @param response 
     * @returns 
     */
    (response: AxiosResponse<any, any>) => {
        if (!flag) return response; // 登录过期, 不再接收接口响应
        const { data, config: { url }, headers } = response;

        // 流数据接口另作处理
        if (url && blobList.includes(url)) {
            // 文件blob流
            return Promise.resolve({ blob: data, headers });
        }

        // 常规数据接口
        if (data && data.code) {
            if (data.code === '8000') {
                return Promise.resolve(data);
            } else {
                errorMessage(data.msg);
                return Promise.reject(data);
            }
        } else {
            if (data.errorCode === '2401') {
                flag = false;
                loading(intl.get("DICTIONARY.BACK_LOGIN"), 3, () => {
                    // 延迟等待时间结束后跳转登录页
                    window.location.href = "/";
                    // 重置接口禁止开关, 允许接口进入
                    flag = true;
                })
                return Promise.resolve(data);
            } else if (data.errorCode) {
                errorMessage(data.errorMsg);
                return Promise.reject(data);
            } else {
                return Promise.reject(data);
            }
        }
    },

    /**
     * 响应失败
     * @param err 
     * @returns 
     */
    (error) => {
        const { message, response, code } = error;
        const status = response?.status;
        switch (status) {
            case 404:
                errorMessage(message);
                break;
            case 504:
                errorMessage(intl.get('ERROR.ERROR_504'));
                break;
            default: ;
        }
        switch (code) {
            case "ECONNABORTED":
                errorMessage(intl.get("ERROR.REQUEST_OVERTIME"));
                break;
            default: ;
        }

        console.log('响应失败', error);
        // 返回 业务失败信息
        return Promise.reject(error);
    }
);

// 导出
export default axiosInstance;