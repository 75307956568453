import React, { PureComponent } from "react";
import { List } from "antd";
import intl from "react-intl-universal";
import style from "./index.module.scss";

// 私有化样式
const { JoinItemPage, navBar, list, listItem } = style;

// 从storage中获取查询报告
const getQueryReport = () => {
  return JSON.parse(localStorage.getItem("queryReport")) || [];
};

export default class joinItem extends PureComponent {
  /**
   * 返回查询页处理函数
   */
  handleJump = (page, patientId) => {
    this.props.callback(page, patientId);
  };

  // 渲染
  render() {
    // 模板
    return (
      <div className={JoinItemPage}>
        {/* 顶部导航栏 */}
        <div className={navBar}>
          <button onClick={() => this.handleJump(0)}>&lt;</button>
          <h1>{intl.get("PATIENT.HISTORY_EXAMS")}</h1>
        </div>
        {/* 列表 */}
        <div className={list}>
          <List
            size="large"
            bordered
            dataSource={getQueryReport()}
            renderItem={(item) => (
              <List.Item
                className={listItem}
                onClick={() => this.handleJump(2, item.id)}
              >
                {item.proName}
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}
