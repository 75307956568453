import * as ActionTypes from '../ActionTypes';
import { patientSearch } from "../../pages/patientsList/patientListInfo"
import { reduxSearchData } from "../../utils/dataUtils"

function patients(
  state = {
    patientsList: { list: [], total: 0 },
    patientsSearch: reduxSearchData(patientSearch),
    patientsOrg: { gradeName: [], clazzName: [] },
    QRcanvas: {},
  },
  action
) {
  switch (action.type) {
    case ActionTypes.SET_PATIENT_LIST:
      return {
        ...state,
        patientsList: action.data
      };
    case ActionTypes.SET_PATIENT_SEARCH:
      return {
        ...state,
        patientsSearch: { ...state.patientsSearch, ...action.data }
      };
    case ActionTypes.SET_PATIENT_ORG:
      return {
        ...state,
        patientsOrg: { ...state.patientsOrg, ...action.data }
      };
    case ActionTypes.SET_QR_CANVAS:
      return {
        ...state,
        QRcanvas: action.data
      };

    default:
      return state;
  }
}

export default patients;
