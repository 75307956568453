import intl from "react-intl-universal"
/*
//结果的数据结构
const lastRes = {
	status: '',  //形态
	rightScope:'',//参考范围
	res: {
		label: '', //结果
		advise: "",//建议
		color: ""  //颜色标亮
	}
}
*/

/**
 * 评定结果与建议
 * @param {Object} quickData
 */
export function getATRRes(quickData: any[]) {
	let absMaxData = Math.max(Math.abs(quickData[0]), Math.abs(quickData[1]))
	let res: any = ''
	if (absMaxData >= 5 && absMaxData < 7) {
		res = {
			label: intl.get("EXAM.SUSPICIOUS"),
			advise: intl.get("EXAM.SUSPICIOUS_ADVISE")/* 建议至正规医院专科门诊进行无辐射三维超声脊柱侧弯评估。 */,
			color: 'orange'
		}
	} else if (absMaxData >= 7 && absMaxData < 10) {
		res = {
			label: intl.get("EXAM.SUSPICIOUS_2"),
			advise: intl.get("EXAM.SUSPICIOUS_ADVISE"),
			color: 'orange'
		}
	} else if (absMaxData >= 10) {
		res = {
			label: intl.get("EXAM.HIGH_SUSPICIOUS"),
			advise: intl.get("EXAM.HIGH_SUSPICIOUS_ADVISE"),
			color: 'red'
		}
	} else if (absMaxData < 5 && absMaxData !== 0) {
		res = {
			label: intl.get("EXAM.RIGHT"),
			advise: intl.get("EXAM.RIGHT_ADVISE"),
			color: "green"
		}
	} else if (absMaxData === 0) {
		res = {
			label: intl.get("EXAM.NO_DATA"),
			advise: intl.get("EXAM.NO_DATA_ADVISE"),
			color: "gray"
		}
	} else {
		res = {
			label: intl.get("EXAM.NO_DATA"),
			advise: intl.get("EXAM.NO_DATA_ADVISE"),
			color: "gray"
		}
	}
	return res
}



/**
 * 
 * @param {*} val 
 * @returns 
 */
export function getATRImgRes(val: number) {
	let res: any = {}
	if (val > -5 && val < 5) {
		res = {
			className: "normal",
			color: "g_"
		}
	} else if (val >= 5 && val < 7) {
		res = {
			className: "mildly_right",
			color: "b_"
		}
	} else if (val <= - 5 && val > - 7) {
		res = {
			className: "mildly_left",
			color: "b_"
		}
	} else if (val >= 7 && val < 10) {
		res = {
			className: "mildly_right",
			color: "y_"
		}
	} else if (val <= - 7 && val > - 10) {
		res = {
			className: "mildly_left",
			color: "y_"
		}
	} else if (val >= 10) {
		res = {
			className: "highly_right",
			color: "r_"
		}
	} else if (val <= -10) {
		res = {
			className: "highly_left",
			color: "r_"
		}
	} else {
		res = {
			className: "normal",
			color: "g_"
		}
	}
	res.angle = val
	return res
}
/**评定形态与参考范围****************************************************************************************************************** */
/**
 * 评定形态
 * 使用时屏幕面向操作者
 * 
 */
export function getExamStatus(obj: { dataType: number; data: any; name: any }): any {
	let data = obj.dataType ? obj.data : obj;
	// 0:快速筛查；1：常规筛查（胸段、胸腰段、腰段）；2：常规筛查：自由模式；3：常规筛查（侧向弯曲、身体平衡、脊柱后凸、前后屈伸）
	if (obj.dataType === 3) {
		let lastRes = {
			status: '',
			res: {}
		}
		let rightScope: any = ''
		switch (obj.name) {
			case 'lateralBending':
				lastRes = setLBSatatus(data)
				rightScope = '-45°~45°'
				break;
			case 'bodyBalance':
				lastRes = { status: setBBSatatus(data), res: getATRRes([data, 0]) }
				rightScope = '-5°~5°'
				break;
			case 'hunchbackAngle':
				lastRes = setHASatatus(data)
				rightScope = '50°~70°'
				break;
			case 'flexionExtension':
				rightScope = '45°~90°'
				lastRes = setFESatatus(data)
				break;
			default:
				rightScope = intl.get("EXAM.UNDETERMINED")/* 待定 */
				lastRes = { status: intl.get("EXAM.UNDETERMINED_EVA")/* 评估标准待定 */, res: {} }
				break;
		}
		return {
			...lastRes, rightScope
		}
	} else {
		let lastRes: any = {
			status: '',
			res: {}
		}
		let rightScope = ''
		switch (obj.name) {
			case 'rotationAngle':
				rightScope = '30°~0~30°'
				lastRes = setRASatatus(Math.max(Math.abs(data[0]), Math.abs(data[1])))
				break;
			default:
				rightScope = '见下表'
				lastRes = { status: setQuickStatus(data), rightScope: '见下表', res: getATRRes(data) }
				break;
		}
		return {
			...lastRes, rightScope
		}
	}
}

/**obj.dataType === 0,1的评定形态******************************************** */
function setQuickStatus(data: any[]) {
	let maxData = Math.abs(data[0]) > Math.abs(data[1]) ? data[0] : data[1]
	if (Math.abs(data[0]) === Math.abs(data[1]) && Math.abs(data[0]) === 0) {
		return intl.get("EXAM.LR_CONSISTENT")/* 左右平衡 */
	} else if (Math.abs(data[0]) === Math.abs(data[1]) && Math.abs(data[0]) !== 0) {
		return intl.get("EXAM.LR_ERR_CONSISTENT")/* 左右偏差一致 */
	}
	if (maxData < 0) {
		return intl.get("EXAM.LLRH")/* 左低右高 */
	} else if (maxData > 0) {
		return intl.get("EXAM.LHRL")/* 左高右低 */
	} else {
		return intl.get("EXAM.NULL")
	}
}
/*obj.dataType === 3******************************************** */
// 异常
function setUnusualRes() {
	return { label: intl.get("EXAM.UNUSUAL")/* 异常 */, color: 'red', advise: intl.get("EXAM.UNUSUAL_ADVISE")/* 建议至正规医院专科门诊进行无辐射三维超声脊柱侧弯评估。 */ }
}
// 正常
function setUsualRes() {
	return { label: intl.get("EXAM.RIGHT")/* 无侧弯 */, color: 'green' }
}
// 暂无数据
function setNoDataRes() {
	return { label: '', color: 'gray' }
}
/**
 * 侧向弯曲
 * @param {*} data 
 * @returns 
 */
function setLBSatatus(data: number) {
	let res = {};
	let status;
	switch (true) {
		case Math.abs(data) <= 45 && data !== 0:
			status = "";
			res = setUsualRes()
			break;
		case data < -45:
			status = '';
			res = { ...setUnusualRes(), label: intl.get("EXAM.UNUSUAL_LEFT")/* 左侧异常 */ }
			break;
		case data > 45:
			status = '';
			res = { ...setUnusualRes(), label: intl.get("EXAM.UNUSUAL_RIGHT")/* 右侧异常 */ }
			break;
		case data === 0:
			status = intl.get("EXAM.NO_DATA")/* 暂无检测数据 */;
			res = setNoDataRes();
			break;
		default:
			status = ""
			res = setNoDataRes();
			break;
	}
	return { status, res }
}
/**
 * 身体平衡
 * @param {*} data 
 * @returns 
 */
function setBBSatatus(data: number) {
	return setQuickStatus(data < 0 ? [data, 0] : [0, data])
}
/**
 * 脊柱后凸
 * @param {*} data 
 * @returns 
 */
function setHASatatus(data: number) {
	let res = {};
	let status;
	switch (true) {
		case data >= 50 && data <= 70:
			status = intl.get("EXAM.RIGHTBACK")/* 正姿 */;
			res = setUsualRes();
			break;
		case data > 0 && data < 50:
			status = intl.get("EXAM.HUNCHBACK")/* 驼背 */;
			res = setUnusualRes();
			break;
		case data > 70 && data <= 90:
			status = intl.get("EXAM.FLATBACK")/* 平背 */;
			res = setUnusualRes();
			break;
		case data === 0:
			status = intl.get("EXAM.NO_DATA")/* 暂无检测数据 */;
			res = setNoDataRes();
			break;
		default:
			status = ""
			res = setNoDataRes();
			break;
	}
	return { status, res }

}
/**
 * 前后屈伸
 * @param {*} data 
 * @returns 
 */
function setFESatatus(data: number) {
	let res = {};
	let status;
	switch (true) {
		case data >= 45 && data <= 90:
			status = '';
			res = setUsualRes();
			break;
		case (data > 0 && data < 45) || data < 0:
			status = '';
			res = setUnusualRes();
			break;
		case data === 0:
			status = intl.get("EXAM.NO_DATA");
			res = setNoDataRes();
			break;
		default:
			status = ""
			res = setNoDataRes();
			break;
	}
	return { status, res }
}

/**
 * 旋转角
 * @param {*} data 
 * @returns 
 */
function setRASatatus(data: number) {
	let res = {};
	let status;
	switch (true) {
		case data >= 30 || data <= -30:
			status = '';
			res = setUnusualRes();
			break;
		case (data > -30 && data < 30):
			status = '';
			res = setUsualRes();
			break;
		case data === 0:
			status = intl.get("EXAM.NO_DATA");
			res = setNoDataRes();
			break;
		default:
			status = ""
			res = setNoDataRes();
			break;
	}
	return { status, res }
}
/******************************************************************************************************************** */