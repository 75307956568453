// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-QRcode .QEcode-out {
  width: 100%;
  height: 80px;
  position: relative;
}
.patient-QRcode .QEcode-out .QEcode-inner {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(90deg);
}
.ant-table-cell .QEcode-out .QEcode-inner,
.app-local .QEcode-out .QEcode-inner {
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/QRcode/index.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAJA;EAOM,kBAAA;EACA,WAAA;EACA,SAAA;EACA,wBAAA;AAAN;AAKA;;EAGM,cAAA;AAJN","sourcesContent":[".patient-QRcode {\n  .QEcode-out {\n    width: 100%;\n    height: 80px;\n    position: relative;\n\n    .QEcode-inner {\n      position: absolute;\n      right: 10px;\n      top: 10px;\n      transform: rotate(90deg);\n    }\n  }\n}\n\n.ant-table-cell,.app-local {\n  .QEcode-out {\n    .QEcode-inner {\n      margin: 0 auto;\n    }\n\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
