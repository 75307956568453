import { Form, Input, Button } from 'antd';
import intl from 'react-intl-universal';
import { UserOutlined, LockOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { login } from '../../actions/login';
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { formPwByType } from "../../utils/dataUtils";
import VerifyCode from "./verifyCode/index";
import ForgetPwd from "./forgetPwd";
import './index.less';
import { useState } from 'react';
import { setCurrentUser } from '../../redux/actions/users'

function LoginUsername() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loginStatus, setLoginStatus] = useState(false)
    const onFinish = (values) => {
        values.pw = formPwByType(values.pw, '.')
        values.picCode = formPwByType(values.picCode, '+')
        setLoginStatus('loading')
        dispatch(login(history, values)).then(res => {
            setLoginStatus(res)
            dispatch(setCurrentUser(res))
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const wrapperCol = { offset: 4, span: 16 }
    const iconStyle = { fontSize: '24px', color: '#ccc' }
    return (
        <Form
            name="login"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='login-form'
        >
            <Form.Item label="" name="username" className='login-form-item' wrapperCol={wrapperCol} rules={[
                {
                    required: true,
                    message: intl.get('LOGIN.NAME_NULL'),
                },
            ]}
            >
                <Input prefix={<UserOutlined style={iconStyle} />} />
            </Form.Item>
            <Form.Item label="" name="pw" className='login-form-item' wrapperCol={wrapperCol} rules={[
                {
                    required: true,
                    message: intl.get('LOGIN.PASS_NULL'),
                },
            ]}
            >
                <Input.Password prefix={<LockOutlined style={iconStyle} />} />
            </Form.Item>
            <Form.Item label="" name="picCode" className='login-form-item login-form-item-code' wrapperCol={wrapperCol} rules={[
                {
                    required: true,
                    message: intl.get('LOGIN.CODE_NULL'),
                },
            ]}
            >
                <Input
                    prefix={<SafetyCertificateOutlined style={iconStyle} />}
                    maxLength="4"
                    suffix={<VerifyCode height="56" loginStatus={loginStatus} />} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}

            >
                <div className='login_username_login_btn'>
                    <Button type="primary" htmlType="submit" className='login_form_btn'>
                        {intl.get('LOGIN.LOGIN_NAME')}
                    </Button>
                    <ForgetPwd />
                </div>
            </Form.Item>

        </Form>
    )
}
export default withRouter(LoginUsername)