
import intl from 'react-intl-universal';

/**类别选项 */
export const classOptions = [
    {
        value: 0,
        key: '0',
        label: "EXE_PRESCRIPTION.CLASS1",
    },
    {
        value: 1,
        key: '1',
        label: "EXE_PRESCRIPTION.CLASS2",
    }
]

export const sysPrescriptionSearch = [
    {
        name: 'type',
        label: "ACTIONWAREHOUSE.CLASS", // 类别
        value: "",
        type: "select",
        options: classOptions,
        colSpan: 3,
        offset: 0
    },
    {
        name: 'name',
        label: 'EXE_PRESCRIPTION.NAME',
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 3,
        offset: 0
    },
]

/**
 * 列表
 * @returns 
 */
export function systemrescriptionColumns() {
    return [
        {
            title: intl.get('ACTIONWAREHOUSE.CLASS'),
            dataIndex: 'typeName',
            filterSearch: true,
            sorter: false,
            width: 90,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.NAME'),
            dataIndex: 'name',
            sorter: false,
            width: 120,
            align: 'center',
            listCol: true,
        },
        {
            title: intl.get('EXE_PRESCRIPTION.ACTION_PROJECT'),
            dataIndex: 'num',
            sorter: false,
            width: 80,
            align: 'center',
            listCol: true,
            render: (_, record) => <span>{intl.get('EXE_PRESCRIPTION.ACTION_NUM', { num: record.num })}</span>,
        },
        {
            title: intl.get('COMMON.REMARKS'),
            dataIndex: 'remark',
            sorter: false,
            width: 100,
            align: 'center',
            listCol: true,
        },

    ];
}