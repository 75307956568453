/* eslint-disable react/jsx-no-target-blank */

import './index.less'
import intl from 'react-intl-universal';
import gonanIcon from "../../static/images/gongan-icon.png"
function Footer(props) {

  return (
    <div className='footer'>
      <div className='info'>{intl.get("INDEX.COMPANY_NAME")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{intl.get("INDEX.COMPANY_TELL_TITLE")}{intl.get("INDEX.COMPANY_TELL")}</div>
      <div className='info'>{intl.get("INDEX.COMPANY_ADDRESS_TITLE")}{intl.get("INDEX.COMPANY_ADDRESS")}</div>
      <div className='info'><a href='https://beian.miit.gov.cn'>粤ICP备2022119142号-2</a></div>
      <div style={{ width: '300px', margin: '0 auto', padding: "20px 0" }}>
        <a target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010345"
          style={{ display: 'inline-block', textDecoration: 'none', height: '20px', lineGeight: '20px' }}>
          <img src={gonanIcon} style={{ float: 'left' }} alt='' />
          <p style={{ float: 'left', height: '20px', lineHeight: '20px', margin: ' 0px 0px 0px 5px', color: '#939393', }}>
            粤公网安备 44030502010345号
          </p>
        </a>
      </div>
    </div>
  )
}
export default Footer