import { ATRResultMap, getQuickATRResult } from "@/utils/report";
import intl from "react-intl-universal";

export const examineSearch = [
  {
    name: "schoolId",
    label: "PATIENT.SCHOOL", // 受检单位
    value: "",
    type: "select",
    options: [],
    colSpan: 3,
    offset: 0,
  },
  {
    name: "typeId",
    label: "EXAM.EXAM_TYPE", // 检测类型
    value: "",
    type: "select",
    options: [
      {
        value: "0",
        label: "EXAM.QUICK_EXAM_TITLE",
      },
      {
        value: "1",
        label: "EXAM.NORMAL_EXAM_TITLE",
      },
    ],
    colSpan: 3,
    offset: 0,
  },
  // {
  //     name: 'proId',
  //     label: 'EXAM.EXAM_PROJECT', // 检测项目
  //     value: "",
  //     type: "select",
  //     options: [],
  //     colSpan: 4,
  //     offset: 0
  // },
  {
    name: "resultId",
    label: "EXAM.EXAM_DETAILS", // 检测结果
    value: "",
    type: "select",
    options: [],
    colSpan: 3,
    offset: 0,
  },
  {
    name: "name",
    label: "PATIENT.NAME",
    value: "",
    type: "input",
    defaultValue: "",
    colSpan: 3,
    offset: 0,
  },
];

export function examsColumns() {
  return [
    {
      title: intl.get("PATIENT.STUDENT_ID"),
      dataIndex: "studentId",
      filterSearch: true,
      sorter: true,
      width: 120,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "studentId",
    },
    {
      title: intl.get("PATIENT.SCHOOL"),
      dataIndex: "schoolName",
      filterSearch: true,
      sorter: true,
      width: 120,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "schoolName",
    },
    // {
    //   title: intl.get("EXAM.EXAM_PROJECT"),
    //   dataIndex: "proName",
    //   filterSearch: true,
    //   sorter: true,
    //   width: 150,
    //   align: "center",
    //   excelCol: true,
    //   listCol: true,
    //   excelKey: "proName",
    // },
    {
      title: intl.get("PATIENT.NAME"),
      dataIndex: "name",
      onFilter: (value: any, record: { name: string }) =>
        record.name.startsWith(value),
      sorter: true,
      width: 120,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "name",
    },
    {
      title: intl.get("PATIENT.AGE"),
      dataIndex: "age",
      sorter: true,
      width: 80,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "age",
    },
    {
      title: intl.get("PATIENT.BIRTHDATA"),
      dataIndex: "birthDate",
      sorter: true,
      width: 80,
      align: "center",
      excelCol: true,
      listCol: false,
      excelKey: "birthDate",
    },
    {
      title: intl.get("PATIENT.SEX"),
      dataIndex: "sex",
      filterSearch: true,
      width: 80,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "sex",
    },
    {
      title: intl.get("PATIENT.PRIMARY"),
      dataIndex: "gradeName",
      filterSearch: true,
      width: 80,
      align: "center",
      excelCol: true,
      listCol: false,
      excelKey: "gradeName",
    },
    {
      title: intl.get("PATIENT.SECONDARY"),
      dataIndex: "clazzName",
      filterSearch: true,
      width: 80,
      align: "center",
      excelCol: true,
      listCol: false,
      excelKey: "clazzName",
    },
    {
      title: intl.get("PATIENT.EXAM_DATE"),
      dataIndex: "examTime",
      filterSearch: true,
      sorter: true,
      width: 150,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "examTime",
    },
    {
      title: intl.get("EXAM.QUICK_EXAM_COL_NAME"),
      dataIndex: "quickData",
      width: 120,
      align: "center",
      excelCol: true,
      listCol: false,
      excelKey: "quickData",
    },
    {
      title: intl.get("EXAM.EXAM_DETAILS"),
      dataIndex: "res",
      width: 120,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "res",
      render: (_: any, record: any) => {
        const quickData = record.resultJson && JSON.parse(record.resultJson)[0];
        const color = quickData?.data
          ? ATRResultMap.get(
              getQuickATRResult(quickData.data, quickData.exerciseStatus).key
            )?.color
          : "";
        return <div style={{ color }}>{record.res}</div>;
      },
    },
    {
      title: "快筛数据",
      dataIndex: "quickData",
      width: 100,
      align: "center",
      excelCol: false,
      listCol: true,
      render: (value: any, record: any) => (
        <div>{record.typeId === 0 ? value : ""}</div>
      ),
    },
    {
      title: intl.get("PATIENT.DOCTOR"),
      dataIndex: "doctorName",
      width: 100,
      align: "center",
      excelCol: true,
      listCol: true,
      excelKey: "doctorName",
    },
    {
      title: intl.get("EXAM.EXAM_DATA_ALL"),
      dataIndex: "quickDataList",
      width: 100,
      align: "center",
      excelCol: false,
      listCol: false,
      excelKey: "quickDataList",
    },
  ];
}
export const stones = [
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
  "T6",
  "T7",
  "T8",
  "T9",
  "T10",
  "T11",
  "T12",
  "L1",
  "L2",
  "L3",
  "L4",
  "L5",
];
