// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gltqkYzg7P43wcnBbz6x {
  /* 菜单项 */
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item,
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu {
  /* 图标 */
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item .iconfont,
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu .iconfont {
  font-size: 18px;
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item:hover a,
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu:hover a,
.gltqkYzg7P43wcnBbz6x .ant-menu-item:hover .iconfont,
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu:hover .iconfont {
  color: #999999;
}
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu-active,
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu-selected,
.gltqkYzg7P43wcnBbz6x i.ant-menu-submenu-arrow::before,
.gltqkYzg7P43wcnBbz6x i.ant-menu-submenu-arrow::after,
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu-title:hover {
  color: #999999;
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item::after {
  border-color: #98D5B9;
}
.gltqkYzg7P43wcnBbz6x .ant-menu-submenu-title:active,
.gltqkYzg7P43wcnBbz6x .ant-menu-item:active {
  background-color: rgba(152, 213, 185, 0.2);
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item.ant-menu-item-selected {
  background-color: rgba(152, 213, 185, 0.2);
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item.ant-menu-item-selected a,
.gltqkYzg7P43wcnBbz6x .ant-menu-item.ant-menu-item-selected .iconfont {
  color: #999;
}
.gltqkYzg7P43wcnBbz6x .ant-menu-item-selected {
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/layout/components/SiderMenu/index.module.less"],"names":[],"mappings":"AAAA;EACE,QAAQ;AACV;AAFA;;EAKE,OAAO;AACT;AANA;;EAUgB,eAAA;AAAhB;AAGY;;;;EAIQ,cAAA;AADpB;AAhBA;;;;;EA2BY,cAAA;AAJZ;AAvBA;EAgCY,qBAAA;AANZ;AA1BA;;EAqCY,0CAAA;AAPZ;AA9BA;EA0CY,0CAAA;AATZ;AAjCA;;EA8CgB,WAAA;AAThB;AArCA;EAmDY,WAAA;AAXZ","sourcesContent":[".siderMenu {\n\n    :global {\n\n        /* 菜单项 */\n        .ant-menu-item,\n        .ant-menu-submenu {\n\n            /* 图标 */\n            .iconfont {\n                font-size: 18px;\n            }\n\n            &:hover {\n\n                a,\n                .iconfont {\n                    color: #999999;\n                }\n            }\n        }\n\n        .ant-menu-submenu-active,\n        .ant-menu-submenu-selected,\n        i.ant-menu-submenu-arrow::before,\n        i.ant-menu-submenu-arrow::after,\n        .ant-menu-submenu-title:hover {\n            color: #999999;\n        }\n\n        // .ant-menu-submenu::after,\n        .ant-menu-item::after {\n            border-color: #98D5B9;\n        }\n\n        .ant-menu-submenu-title:active,\n        .ant-menu-item:active {\n            background-color: rgba(152, 213, 185, 0.2);\n        }\n\n        // .ant-menu-submenu,\n        .ant-menu-item.ant-menu-item-selected {\n            background-color: rgba(152, 213, 185, 0.2);\n\n            a,\n            .iconfont {\n                color: #999;\n            }\n        }\n\n        .ant-menu-item-selected {\n            color: #999;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siderMenu": `gltqkYzg7P43wcnBbz6x`
};
export default ___CSS_LOADER_EXPORT___;
