import store from '../store';

/*验证年龄*/
export const validatePersonAge = (str) => {
  const age_type = store.getState().checkIn.checkInfo.age_type;
  let regY = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/; //年龄是1-120之间有效
  let regM = /^0?[1-9]$|^1[0-2]$/; //月是1-12之间有效
  let age = parseInt(str, 10);
  if (['Y', '', undefined].includes(age_type) && !regY.test(age)) {
    return false;
  } else if (age_type === 'M' && !regM.test(age)) {
    return false;
  }
  return true;
};



/*验证金额 */
export const validateMoney = (str) => {
  let regMoney = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
  if (!regMoney.test(str)) {
    return false;
  }
  return true;
};

/*验证正整数 */
export const validateInt = (str) => {
  let regInt = /^[1-9]\d*$/;
  if (!regInt.test(str)) {
    return false;
  }
  return true;
};

/*验证长度 */
export const validateLen = (str) => {
  let regLen = /^\S{1,20}$/g;
  if (!regLen.test(str)) {
    return false;
  }
  return true;
};

/*验证密码(^\s*)*/
export const validatePwd = {
  // eslint-disable-next-line no-useless-escape
  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^(`~!@#$%^&*()_+<>?:"{},.\/;'·！#￥（——）：；“”‘、，|《。》？、【】[\])(\u4e00-\u9fa5)]{8,16}$/,
}

/*验证手机号*/
export const regPhone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
export const validatePhone = { pattern: regPhone }

/*验证邮箱*/
export const validateEmail = {
  pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
}

/*验证IP地址 */
export function isValidIP(ip) {
  let reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  return reg.test(ip);
}

/*验证端口 */
export function isValidHost(host) {
  let reg = /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/
  return reg.test(host);
}
