import { message } from "antd";

export function downloadBtCodeZip(dataString,msg,filename) {
    // 解析base64的字符串数据转成二进制数据
    const byteCharacters = atob(dataString); 
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
         byteNumbers[i] = byteCharacters.charCodeAt(i); 
        } 
    const byteArray = new Uint8Array(byteNumbers); 
    
    // Create blob object 
    const blob = new Blob([byteArray], { type: 'application/zip' });
    let url = window.URL.createObjectURL(blob)
    const link = document.createElement('a') // 创建a标签
    link.href = url
    link.setAttribute("download", filename);
    link.click()
    message.success(msg)
    URL.revokeObjectURL(url) // 释放内存
}