
import { withRouter } from "react-router-dom";
import LoginPhone from "./loginPhone.js"
import LoginUsername from "./loginUsername.js"
import './index.less'
function LoginForm(props) {
    const { loginMode } = props
    return (
        <>
            {loginMode === "username" && <LoginUsername />}
            {loginMode === "phone" && <LoginPhone />}
        </>
    )
}
export default withRouter(LoginForm)