import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// redux存储器
import store from "./store";
// 注入store
import { Provider } from 'react-redux';
// 初始化语言
import { initLanguage } from './static/locales/index.js';
import 'echarts/extension/bmap/bmap';

initLanguage(); // 初始化语言

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// 性能监测
reportWebVitals();
