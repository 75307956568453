import { Tag } from 'antd';
import intl from 'react-intl-universal';
import QRcode from '../../components/QRcode';
export const btCodeSearch = [
    {
        name: 'code',
        label: "BTCODE.SEARCH_NAME",
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 5,
        offset: 0,
        maxLength: 10
    },
    {
        name: 'downloadNum',
        label: "BTCODE.STATUS",
        value: "",
        type: "select",
        options: [
            { value: 0, label: "BTCODE.UNDOWNLOAD" },
            { value: 1, label: "BTCODE.DOWNLOADED" },
        ],
        colSpan: 3,
        offset: 1,
    }
]

export function btCodeColumns() {
    return [
        {
            title: intl.get('BTCODE.NAME'),
            dataIndex: 'code',
            sorter: true,
            width: 120,
            align: 'center',
        },
        {
            title: intl.get('BTCODE.QRCODE'),
            dataIndex: 'name',
            width: 120,
            align: 'center',
            render: (_, record) => <QRcode data={record.code} QRParams={{ size: 30 }} />,
        },
        {
            title: intl.get('BTCODE.TIME'),
            dataIndex: 'batch',
            sorter: true,
            width: 150,
            align: 'center',
        },
        {
            title: intl.get('BTCODE.STATUS'),
            dataIndex: 'downloadNum',
            width: 120,
            align: 'center',
            render: (_, record) => {
                if (record.downloadNum === 1) return (<Tag color='success' >{intl.get('ACTION.DOWNLOADEDED')}</Tag >);
                else return (<Tag color='warning'>{intl.get('ACTION.NOT_DOWNLOADED')}</Tag>);
            },
        }
    ];
}