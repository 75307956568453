import * as ActionTypes from '../ActionTypes';
import { examineSearch } from '../../pages/examine-history/examsInfo';
import { reduxSearchData } from "../../utils/dataUtils"

function exams(
    state = {
        examsList: { list: [], total: 0 },
        examsSearch: reduxSearchData(examineSearch)
    },
    action
) {
    switch (action.type) {
        case ActionTypes.SET_EXAMS_LIST:
            return {
                ...state,
                examsList: action.data
            };
        case ActionTypes.SET_EXAMS_SEARCH:
            return {
                ...state,
                examsSearch: { ...state.examsSearch, ...action.data }
            };

        default:
            return state;
    }
}

export default exams;
