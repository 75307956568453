
// import intl from "react-intl-universal"
import { useEffect, useRef } from 'react'

function VideoPart(props) {

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('contextmenu', handleContextMenu);
            }
        };
    }, []);
    return (
        <video ref={videoRef} src={props.videoUrl} controls controlsList="nodownload" width={props.width} height={props.height} autoPlay={props.autoPlay}>
            {/* <source  type="video/mp4" />
            {intl.get("ACTION.BROWSER_CANNOT_PLAY_VIDEO")} */}
        </video>
    )
}
export default VideoPart
