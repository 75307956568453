// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #293237;
  color: #fff;
  padding: 10px 0 0 0;
}
.footer .info {
  line-height: 30px;
  height: 30px;
}
.footer .info a {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/index.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;EACA,mBAAA;AACJ;AAPA;EAQQ,iBAAA;EACA,YAAA;AAER;AAXA;EAWY,WAAA;AAGZ","sourcesContent":[".footer{\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    background-color: #293237;\n    color: #fff;\n    padding: 10px 0 0 0;\n    .info{\n        line-height: 30px;\n        height: 30px;\n        a{\n            color: #fff;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
