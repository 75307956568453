// 路由
import { Link, useLocation } from 'react-router-dom';

// 组件
import { Menu } from 'antd';
import { useEffect, useMemo, useState } from 'react';

// 翻译
import intl from 'react-intl-universal';

// 样式
import style from './index.module.less';
// iconfont (类名格式)
import '@/static/iconfont/class/iconfont.css';

const { siderMenu } = style;

/**
 * 侧边菜单组件 ------------------------------------------------------------
 * @param props 
 * @returns 
 */
export default function SiderMenu(props: { routers: API.TRouterConfig[] }) {
    const { routers } = props;

    // 定义高亮菜单项key
    const [menuKey, setMenuKey] = useState<string[]>();

    // 获取 路由信息
    const location = useLocation();


    // 组装 Menu 组件 items 结构数据 && 缓存处理 && 监听路由配置
    const items = useMemo(() => routers.map((menu) => {
        // 不需要渲染到菜单的路由
        if (menu.noRender)   return null;
       
            return {
                /* 菜单显示文本 */
                label: menu.path ? <Link to={menu.path}>{intl.get("MENU." + menu.label)}</Link> : intl.get("MENU." + menu.label),
                /* 菜单显示图标 */
                icon: menu.icon && <i className={'iconfont icon-' + menu.icon} />,
                /* url */
                key: menu.path || menu.label,
                // 子菜单
                children: menu.children && menu.children.map(child => {
                    return {
                        /* 菜单显示文本 */
                        label: child.path ? <Link to={child.path}>{intl.get("MENU." + child.label)}</Link> : intl.get("MENU." + child.label),
                        /* 菜单显示图标 */
                        icon: child.icon && <i className={'iconfont icon-' + child.icon} />,
                        /* url */
                        key: child.path || child.label,
                    }
                })
            }
       }), [routers]);


    // 监听路由变化 && 动态改变菜单项高亮状态 -- 外部输入路由对应菜单项仍然高亮
    useEffect(() => {
        // 更新高亮菜单
        setMenuKey([location.pathname])
        // 监听 路由地址
    }, [location.pathname]);

    // 渲染
    return <Menu mode="inline" items={items}
        className={siderMenu}
        style={{ height: '100%', }}
        selectedKeys={menuKey} />
}
