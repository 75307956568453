// 懒加载组件
import { Suspense } from 'react';

// 路由
import { Switch, Route, Redirect } from 'react-router-dom';

// 错误页面
import Error from "@/pages/error";
import intl from 'react-intl-universal';


/**
 * 路由入口组件 --------------------------------------------------------------
 * @param props 
 * @returns 
 */
export default function RouterView(props: { routers: API.TRouterConfig[] }) {
    const { routers } = props;

    // 路由组件进行懒加载
    const lazyComponent = routers?.map((route,index) => {
        if (!route.children) {
            return  (<Route exact key={route.path || route.label+index} path={route.path}
                component={() => (
                    /* 懒加载组件需要 Suspense 组件包裹 */
                    <Suspense
                        /* 加载过程的展示内容 */
                        fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                        {route.component && <route.component />}
                    </Suspense>
                )} />);
        } else {
            return route.children.map((child,index0) => {
                return  <Route exact key={child.label+index0} path={child.path}
                    component={() => (
                        /* 懒加载组件需要 Suspense 组件包裹 */
                        <Suspense
                            /* 加载过程的展示内容 */
                            fallback={<div>{intl.get("ACTION.LOADING")}</div>}>
                            {child.component && <child.component />}
                        </Suspense>
                    )} />;
            });
        }
    })


    // 渲染
    return (
        <Switch>
            {/* 路由页面 */}
            {lazyComponent}

            {/* 严格控制 `来的路径` && 重定向到 公共页面 -- currentUser */}
            <Redirect exact from='/mainPage' to='/mainPage/currentUser' />

            {/* 无效路径 展示404 */}
            <Route path='*' component={Error} />
        </Switch>
    )
}
