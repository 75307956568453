import React, { useState } from "react";
import './index.less'
import { Form, Input, Button } from 'antd';
import intl from 'react-intl-universal';
import { PhoneOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { validatePhone } from "../../utils/regUtils"
import { loginBySms } from "../../actions/login.js"
import CountDown from "./countDown.js"
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux"
import { formPwByType } from "../../utils/dataUtils";

function LoginPhone() {
    const history = useHistory();
    const dispatch = useDispatch();
    const wrapperCol = { offset: 4, span: 16 }
    const iconStyle = { fontSize: '24px', color: '#ccc' }
    const [userPhone, setUserPhone] = useState("")
    const [phoneDisabled, setPhoneDisabled] = useState(false);

    const onFinish = (values) => {
        values.code = formPwByType(values.code, '+')
        dispatch(loginBySms(history, values))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const setPhoneDis = (data) => {
        setPhoneDisabled(data)
    }
    return (
        <Form
            name="loginPhone"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='login-form'
        >
            <Form.Item label="" name="tel" className='login-form-item' wrapperCol={wrapperCol} rules={[
                {
                    required: true,
                    message: intl.get('LOGIN.PHONE_NULL'),
                }, {
                    ...validatePhone,
                    message: intl.get('USERS.TEL_MSG_ERROR')
                }
            ]}
            >
                <Input
                    prefix={<PhoneOutlined style={iconStyle} />}
                    onChange={(e) => { setUserPhone(e.target.value) }}
                    maxLength="11"
                    disabled={phoneDisabled} />
            </Form.Item>
            <Form.Item label="" name="code" className='login-form-item login-form-item-code' wrapperCol={wrapperCol} rules={[
                {
                    required: true,
                    message: intl.get('LOGIN.CODE_NULL'),
                },
            ]}
            >
                <Input
                    maxLength="6"
                    prefix={<SafetyCertificateOutlined style={iconStyle} />}
                    suffix={<CountDown userPhone={userPhone}
                        setPhoneDis={(data) => setPhoneDis(data)} />} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <div className='login_username_login_btn'>
                    <Button type="primary" htmlType="submit" className='login_form_btn'>
                        {intl.get('LOGIN.LOGIN_NAME')}
                    </Button>
                </div>

            </Form.Item>
        </Form>

    )
}
export default withRouter(LoginPhone)